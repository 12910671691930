import { CButton } from "@coreui/react";
import React from "react";
import CsvDownloader from "react-csv-downloader";

const ExportAttemptsButton = ({ TABLE_HEAD = [], data = [] }) => {
  return (
    <CsvDownloader
      className="csv-downloader"
      filename={`attempts-${new Date().toISOString()}.csv`}
      extension=".csv"
      columns={TABLE_HEAD.map(({ accessor, Header }) => ({
        id: accessor,
        displayName: Header,
      })).filter((column) => column.displayName !== "Delete")}
      datas={data}
    >
      <CButton color="dark" type="submit">
        Export
      </CButton>
    </CsvDownloader>
  );
};

export default ExportAttemptsButton;
