export default function useDateTime() {
  // convert date & time to ISO string
  function formetDateTime(date, time) {
    if (date) {
      var formated = new Date(date == "" ? "01/01/2000" : date);

      if (time) {
        var time = time.match(/(\d+)(?::(\d\d))?\s*(p?)/);
        formated.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
        formated.setMinutes(parseInt(time[2]) || 0);
      }
    }
    return formated.toISOString();
  }

  //   convert ISO string to html date
  function getDate(date) {
    const parsedDate = new Date(date);
    return `${parsedDate.getFullYear()}-${
      parsedDate.getMonth() + 1 < 10
        ? "0" + (parsedDate.getMonth() + 1)
        : parsedDate.getMonth() + 1
    }-${
      parsedDate.getDate() < 10
        ? "0" + parsedDate.getDate()
        : parsedDate.getDate()
    }`;
  }

  //   convert ISO string to html time
  function getTime(date) {
    const parsedDate = new Date(date);
    return `${
      parsedDate.getHours() < 10
        ? "0" + parsedDate.getHours()
        : parsedDate.getHours()
    }:${
      parsedDate.getMinutes() < 10
        ? "0" + parsedDate.getMinutes()
        : parsedDate.getMinutes()
    }:${
      parsedDate.getSeconds() < 10
        ? "0" + parsedDate.getSeconds()
        : parsedDate.getSeconds()
    }`;
  }

  // convert ISO strin to MonthName - date - year
  function getDateEasy(date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const parsedDate = new Date(date);
    return `${
      months[parsedDate.getMonth()]
    } ${parsedDate.getDate()}, ${parsedDate.getFullYear()}`;
  }

  // convert ISO strin to MonthName - date - year
  function getDateTimeEasy(date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const parsedDate = new Date(date);
    var hours = parsedDate.getHours();
    var minutes = parsedDate.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${
      months[parsedDate.getMonth()]
    } ${parsedDate.getDate()}, ${hours}:${minutes} ${ampm}`;
  }

  return { formetDateTime, getDateEasy, getDateTimeEasy, getDate, getTime };
}
