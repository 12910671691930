import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

import App from "./App";
import { UserProvider } from "./Hooks/user";
import { Provider } from "react-redux";
import store from './store'

const container = document.getElementById("root");
const root = createRoot(container);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <UserProvider>
        <App />
      </UserProvider>
    </BrowserRouter>
  </Provider>
);

root.render(app);
