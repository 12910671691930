import { useLayoutEffect, useEffect, useState, useRef, useMemo } from "react";
import CIcon from "@coreui/icons-react";
import { cilMagnifyingGlass } from "@coreui/icons";
import useAttempts from "../../Hooks/useAttempts";
import useUser from "../../Hooks/user";
import useDateTime from "../../Hooks/useDateTime";
import {
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import ReactPaginate from "react-paginate";
import { ITEMS_PER_PAEG } from "../../config";
import { Link } from "react-router-dom";
import Checkbox from "../../Components/Checkbox";
import { useRowSelect, useTable } from "react-table";
import QuizSelector from "./components/QuizSelector";
import ExportAttemptsButton from "./components/ExportAttemptsButton";

const ListAttempts = () => {
  const user = useUser();
  const clientId = user.state.user.client;
  const {
    state,
    getAttempts,
    searchQuestions,
    bulkDeleteAttempts,
    exportViewersData,
    getAttemptByQuizId,
  } = useAttempts(clientId);
  const { getDateEasy } = useDateTime();
  const [pageCount, setPageCount] = useState(0);
  const searchRef = useRef();

  const data = useMemo(() => {
    return state.results.map((attempt) => ({
      id: attempt.id,
      viewer: attempt.viewer,
      quizName: attempt.quiz.name,
      score: attempt.score,
      time_taken: Math.round(attempt.time_taken),
      rank: attempt.rank,
      status: attempt.quiz.status,
      date: getDateEasy(attempt.start_date_time),
    }));
  }, [state]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "viewer",
      },
      {
        Header: "Quiz",
        accessor: "quizName",
      },
      {
        Header: "Score",
        accessor: "score",
      },
      {
        Header: "Time Taken",
        accessor: "time_taken",
      },
      {
        Header: "Rank",
        accessor: "rank",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Delete",
        Cell: ({ row: { original } }) => (
          <Link to={"/list-questions/" + original.id + "/delete"}>
            <button type="button" className="btn btn-danger btn-sm">
              Delete
            </button>
          </Link>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data }, useRowSelect, (hooks) => {
    hooks.visibleColumns.push((columns) => {
      return [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
        },
        ...columns,
      ];
    });
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = tableInstance;

  const handlePageClick = (event) => {
    getAttempts(event.selected + 1);
  };

  useLayoutEffect(() => {
    document.title = "List Attempts";
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(state.count / ITEMS_PER_PAEG));
  }, [state]);

  return (
    <div className="list-attempts">
      <div className="heading">
        <h6>List Attempts</h6>
        <hr />
      </div>
      <div className="attempt-name">
        <div className="mb-3">
          <CContainer className="ms-0 ps-0" fluid>
            <form onSubmit={(e) => e.preventDefault()}>
              <CRow className="justify-content-between align-items-center mb-5">
                <CRow className="w-75 ">
                  <CCol sm="auto">
                    <QuizSelector
                      onChange={(e) => getAttemptByQuizId(e.target.value)}
                    />
                  </CCol>
                  <CCol sm="auto">
                    <CFormLabel>Search</CFormLabel>
                    <CInputGroup>
                      <CFormInput
                        className="border-end-0"
                        placeholder="Search"
                        aria-label="Search"
                        ref={searchRef}
                        onChange={(e) => searchQuestions(e.target.value)}
                      />
                      <CInputGroupText className="bg-white">
                        <CIcon icon={cilMagnifyingGlass} />
                      </CInputGroupText>
                    </CInputGroup>
                  </CCol>
                </CRow>

                <CCol sm="auto">
                  <ExportAttemptsButton TABLE_HEAD={columns} data={data} />
                </CCol>
              </CRow>
            </form>
          </CContainer>
        </div>

        {selectedFlatRows.length > 0 && (
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() =>
              bulkDeleteAttempts(
                selectedFlatRows.map(({ original: { id } }) => parseInt(id))
              )
            }
          >
            {`Delete Selected (${selectedFlatRows.length})`}
          </button>
        )}
      </div>

      <div className="quiz-attempts">
        <CTable responsive {...getTableProps()}>
          <CTableHead>
            {headerGroups.map((headerGroup) => (
              <CTableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <CTableHeaderCell scope="col" {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </CTableHeaderCell>
                ))}
              </CTableRow>
            ))}
          </CTableHead>
          <CTableBody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <CTableRow color="light" key={index} {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <CTableDataCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </CTableDataCell>
                  ))}
                </CTableRow>
              );
            })}
          </CTableBody>
        </CTable>
        <div className="d-flex justify-content-between align-items-center">
          <ReactPaginate
            containerClassName="pagination mt-2"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            breakLabel="..."
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            nextPageRel={state.next}
            prevPageRel={state.previous}
            renderOnZeroPageCount={null}
          />
          <p style={{ color: "#7a7a7a" }}>
            Showing {state.results.length} of {state.count}{" "}
            {state.count > 1 ? "attempts" : "attempt"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ListAttempts;
