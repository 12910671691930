import axios from "axios";
import { API_URL } from "../config";

export default function resetPassword(email, site_url) {
  return axios
    .post(`${API_URL}/api/rest-auth/password/reset/`, email, site_url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}
