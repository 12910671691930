import { useLayoutEffect, useEffect, useState } from "react";
import useQuizes from "../../Hooks/useQuizes";
import { Link, useParams } from "react-router-dom";
import useUser from "../../Hooks/user";



const QuizDelete = () => {
    const user = useUser();
    const clientId = user.state.user.client;
  const {Delete} = useQuizes(clientId);
  const { id } = useParams();

  const DeleteInstance = (e) =>{
    Delete(e);
    // alert(e)
  }



  useLayoutEffect(() => {
    document.title = "Delete";
  }, []);

  return (
    <div className="list-attempts">
      <div className="heading">
        <h6>Attempts Delete</h6>
        <hr />
      </div>
      <div className="dataDelete">
          <h6>Are you sure you want to delete this Quiz Instance?</h6>
          <Link to="/list-quiz">
          <button type="button" class="btn btn-primary btn-sm">
              Cancel
              </button></Link>&nbsp;&nbsp;
          <button type="button" class="btn btn-danger btn-sm" value={id} onClick={(e)=>DeleteInstance(e.target.value)}>Delete</button>
      </div>

    </div>
  );
};

export default QuizDelete;
