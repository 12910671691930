import { CFormInput, CFormLabel } from "@coreui/react";
import React from "react";

const UploadField = ({
  label,
  name,
  value,
  onChange,
  imageOnly,
  required = false,
}) => {
  return (
    <div className="mb-3 col-auto col-lg-4">
      <CFormLabel>{label}</CFormLabel>
      <CFormInput
        name={name}
        type="file"
        value={value}
        onChange={onChange}
        required={required}
        accept={imageOnly ? "image/*" : null}
      />
    </div>
  );
};

export default UploadField;
