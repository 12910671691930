import {
  cilAddressBook,
  cilList,
  cilPen,
  cilSpeedometer,
  cilLowVision,
  cilSettings,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CNavGroup, CNavItem } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Quiz",
    icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add new Quiz",
        to: "/add-quiz",
      },
      {
        component: CNavItem,
        name: "List of Quizzes",
        to: "/list-quiz",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Question",
    icon: <CIcon icon={cilPen} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add new Question",
        to: "/add-question",
      },
      {
        component: CNavItem,
        name: "List Questions",
        to: "/list-questions",
      },
      {
        component: CNavItem,
        name: "Question Categories",
        to: "/add-category",
      },
    ],
  },

  {
    component: CNavGroup,
    name: "Polls",
    icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add new Poll",
        to: "/add-polls",
      },
      {
        component: CNavItem,
        name: "List Polls",
        to: "/list-polls",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Reports",
    icon: <CIcon icon={cilLowVision} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Quiz Report",
        to: "/quiz-report",
      },
      {
        component: CNavItem,
        name: "Viewers Data",
        to: "/viewers-data",
      },
    ],
  },
  {
    component: CNavItem,
    name: "Setting",
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    to: "/setting",
  },
];


const _hidePollnav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Quiz",
    icon: <CIcon icon={cilAddressBook} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add new Quiz",
        to: "/add-quiz",
      },
      {
        component: CNavItem,
        name: "List of Quizzes",
        to: "/list-quiz",
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Question",
    icon: <CIcon icon={cilPen} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add new Question",
        to: "/add-question",
      },
      {
        component: CNavItem,
        name: "List Questions",
        to: "/list-questions",
      },
      {
        component: CNavItem,
        name: "Question Categories",
        to: "/add-category",
      },
    ],
  },

  {
    component: CNavGroup,
    name: "Reports",
    icon: <CIcon icon={cilLowVision} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Quiz Report",
        to: "/quiz-report",
      },
      {
        component: CNavItem,
        name: "Viewers Data",
        to: "/viewers-data",
      },
    ],
  },
  {
    component: CNavItem,
    name: "Setting",
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    to: "/setting",
  },
];


export {_hidePollnav, _nav};
