import { CButton, CForm, CImage } from "@coreui/react";
import { useEffect, useState } from "react";
import TextFeild from "../../Components/textFeild";
import TextAreaField from "../../Components/textAreaField";
import UploadField from "../../Components/uploadField";
import useUser from "../../Hooks/user";
import useBussinessSetting from "../../Hooks/useBussinessSetting";

const BussinessSetting = () => {
  const user = useUser();
  const clientId = user.state.user.client;
  const { state, hasLoaded, updateBussinessSetting } =
    useBussinessSetting(clientId);
  const [logo, setLogo] = useState(null);
  const [bg, setBg] = useState(null);
  const [mascot, setMascot] = useState(null);
  const [businessSettings, setBusinessSettings] = useState({
    business_name: "",
    description: "",
    business_logo: null,
    background_image: null,
    mascot_logo: null,
    recent_participant_time: "",
    viewer_app_url:"",
  });
  const [imges, setImges] = useState({});
  // State Variables for the question creation

  const handleSubmit = (e) => {
    e.preventDefault();
      updateBussinessSetting({
        ...state,
        // logo: null,
        settings: {
          business_name: businessSettings.business_name,
          description: businessSettings.description,
          recent_participant_time: businessSettings.recent_participant_time,
          mascot_logo:mascot,
          background_image:bg,
          business_logo:logo,
          viewer_app_url:businessSettings.viewer_app_url
        },
      });

      if (hasLoaded) {
        setBusinessSettings(state.settings);
      }    
    console.log("businessSettings", state.settings);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBusinessSettings({
      ...businessSettings,
      [name]: value,
    });
  };

  
  useEffect(() => {
    document.title = "Setting";
    if (hasLoaded) {
      setBusinessSettings(state.settings);
    }
  }, [state]);

  // const convertBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(file);
  //     fileReader.onload = () => {
  //       resolve(fileReader.result.replace(/^data:.+;base64,/, ""));
  //     };
  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });
  // };

  return (
    <div className="add-question">
      <CForm >
        <div className="col-6 col-md-6">
          <TextFeild
            label="Bussiness Name"
            hint="ABC company"
            name="business_name"
            value={businessSettings.business_name}
            onChange={handleChange}
            
          />
        </div>
        <div className="col-12 col-md-6">
          <TextAreaField
            label="Description"
            hint="We do tis...."
            numberOfRows={3}
            name="description"
            value={businessSettings.description}
            onChange={handleChange}
            
          />
        </div>
        <div className="inline-fields">
          <UploadField
            label="Bussiness Logo"
            imageOnly={true}
            name="business_logo"
            onChange={(e) => {
                setLogo(e.target.files[0]);
            }}
          />

          {(businessSettings.business_logo_url) && (
            <CImage
              rounded
              thumbnail
              src={businessSettings.business_logo_url}
              width={200}
              height={200}
            />
          )}
        </div>
        <div className="inline-fields">
          <UploadField
            label="Background Image (750x500)"
            imageOnly={true}
            name="background_image"
            onChange={(e) => {
              
                setImges({
                  ...imges,
                  background_image: e.target.files[0].file,
                });
                setBg(e.target.files[0]);
              
            }}
          />
          {(businessSettings.background_image_url) && (
            <CImage
              rounded
              thumbnail
              src={businessSettings.background_image_url}
              width={200}
              height={200}
            />
          )}
        </div>
        <div className="inline-fields">
          <UploadField
            label="Mascot Image"
            imageOnly={true}
            onChange={(e) => {
                setImges({
                  ...imges,
                  mascot_logo:e.target.files[0],
                });
         
              setMascot((e.target.files[0]));
            }}
          />
          {(businessSettings.mascot_logo_url) && (
            <CImage
              rounded
              thumbnail
              src={businessSettings.mascot_logo_url}
              width={200}
              height={200}
            />
          )}
        </div>
        <div className="col-12 col-md-6">
          <TextFeild
            label="Recent participant time"
            hint="time in seconds"
            name="recent_participant_time"
            value={businessSettings.recent_participant_time}
            onChange={handleChange}
            
          />
        </div>

        <div className="col-12 col-md-6">
          <TextFeild
            label="Viewer's App URl"
            hint="url"
            name="viewer_app_url"
            value={businessSettings.viewer_app_url}
            onChange={handleChange}
            
          />
        </div>
        
        <div className="d-grid col-2">
          <CButton
          onClick={handleSubmit}
          type="submit">Save</CButton>
        </div>
      </CForm>
    </div>
  );
};

export default BussinessSetting;
