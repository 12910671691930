import {
  CButton,
  CForm,
  CFormCheck,
  CFormLabel,
  CImage,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import useCategories from "../../Hooks/useCategories";
import useQuestions from "../../Hooks/useQuestions";
import useUser from "../../Hooks/user";
import useQuizes from "../../Hooks/useQuizes";
import useDateTime from "../../Hooks/useDateTime";

import "./styles.scss";
import TextFeild from "../../Components/textFeild";
import Selection from "../../Components/selection";
// import TextAreaField from "../../Components/textAreaField";
import UploadField from "../../Components/uploadField";
import { useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { ITEMS_PER_PAEG } from "../../config";
import { quizModes, statusOptions } from "../../constants";
import HowToCreateQuiz from "./howTo";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Card from "./Card";
import * as S from "./QuestionMultiDrag/styles";

const UpdateQuiz = ({ showHelp }) => {
  const { id } = useParams();
  const user = useUser();
  const clientId = user.state.user.client;
  const categories = useCategories(clientId);
  const questions = useQuestions(clientId);
  const { UpdateQuiz, getQuizById } = useQuizes(clientId);
  const { formetDateTime, getDate, getTime } = useDateTime();
  const [pageCount, setPageCount] = useState(0);
  const [sponserImage, setSponserImage] = useState(null);
  const [quizImage, setQuizImage] = useState(null);

  // State Variables for the quiz creation
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [allQuestions, setAllQuestions] = useState([]);
  const [selectedCategoryIds, setSelectedCategories] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [selectedQues, setselectedQues] = useState([]);
  const [allQues, setallQues] = useState([]);
  const [allCheckedQues, setallCheckedQues] = useState([]);
  const [selectedCheckedQues, setselectedCheckedQues] = useState([]);
  const [isCheck, setIsChecked] = useState(false)

  const [quiz, setQuiz] = useState({});

  useEffect(() => {
    document.title = "Edit Quiz";
    getQuizById(id).then((quiz) => setQuiz(quiz));
    setPageCount(Math.ceil(questions.state.count / ITEMS_PER_PAEG));
  }, [questions.state]);

  useEffect(() => {
    // setting questions
    const {
      hasLoaded,
      state: { results: questionsList },
    } = questions;
    if (hasLoaded && quiz.id != null) {
      // add a checked property to each question

      const questionsWithChecked = questionsList.map((question) => {
        const isCecked = quiz.questions.includes(question.id) ? true : false;
        if (isCecked) {
          questions.getQuestionById(question.id).then((data) => {
            setSelectedCategories((selectedCategoryIds) => [
              ...selectedCategoryIds,
              data.category?.toString(),
            ]);
          });
        }
        return {
          ...question,
          checked: isCecked,
        };
      });

      const q = quiz.questions_detail;

      setselectedQues(q);
      setAllQuestions(questionsWithChecked);
      setFilteredQuestions(questionsWithChecked);
    }
  }, [questions.state, quiz.id]);

  // whenever the categories change, update the filtered questions
  useEffect(() => {
    if (selectedCategoryIds.length > 0) {
      const filterQuestions = () => {
        return allQuestions.filter((question) => {
          return selectedCategoryIds.includes(question.category?.toString());
        });
      };

      const questionsall = allQuestions.filter(
        (question) => !quiz.questions.includes(question.id)
      );

      const filterQuestionsCat = () => {
        return questionsall.filter((question) => {
          return selectedCategoryIds.includes(question.category?.toString());
        });
      };
      setFilteredQuestions(filterQuestions());
      setallQues(filterQuestionsCat);
    } else {
      const questionsall = allQuestions.filter(
        (question) => !quiz.questions.includes(question.id)
      );
      setFilteredQuestions(allQuestions);
      setallQues(questionsall);
    }
  }, [selectedCategoryIds]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setQuiz({
      ...quiz,
      [name]: value,
    });
  };

  const handlePageClick = (event) => {
    questions.getQuestions(event.selected + 1);
  };

  const handleCategorySelect = (e) => {
    if (e.target.checked) {
      setSelectedCategories((selectedCategoryIds) => [
        ...selectedCategoryIds,
        e.target.value,
      ]);
    } else {
      const newSelectedCategoryIds = [...selectedCategoryIds];
      if (newSelectedCategoryIds.indexOf(e.target.value) > -1) {
        newSelectedCategoryIds.splice(
          newSelectedCategoryIds.indexOf(e.target.value),
          1
        );
      }
      setSelectedCategories(newSelectedCategoryIds);
    }
  };

  const handleQuestionSelect = (e, questionIndexInFilteredList) => {
    if (e.target.checked) {
      // console.log("checked", e.target.value);
      // console.log("index", questionIndexInFilteredList);
      const newFilteredQuestions = [...filteredQuestions];
      newFilteredQuestions[questionIndexInFilteredList].checked = true;
      setFilteredQuestions(newFilteredQuestions);
    } else {
      // console.log("unchecked", e.target.value);
      const newFilteredQuestions = [...filteredQuestions];
      newFilteredQuestions[questionIndexInFilteredList].checked = false;
      setFilteredQuestions(newFilteredQuestions);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setSelectedCategories(
    //   categories.state.map((category) => category.id.toString()),
    //   () => console.log(selectedCategoryIds)
    // );
    // get the selected questions
    const listOfSelecteedQuestionIds = filteredQuestions
      .filter((question) => question.checked)
      .map((question) => question.id);

    const DraglistOfSelecteedQuestionIds = selectedQues.map(
      (question) => question.id
    );

    // add the selected questions to the quizPayload
    const quizDataPayload = {
      ...quiz,
      questions: DraglistOfSelecteedQuestionIds,
    };
    // console.log("data", quizDataPayload);
    console.log(quizDataPayload);
    UpdateQuiz(quizDataPayload);
  };

  const disablePastDate = (dateObj) => {
    const today = dateObj ? dateObj : new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  ///////////

  const handleGetListWithSetter = (listName) => {
    switch (listName) {
      case "selectedQuestions":
        return { list: selectedQues, setList: setselectedQues };
      case "allQuestions":
        return { list: allQues, setList: setallQues };
      default:
        return { list: selectedQues, setList: setselectedQues };
    }
  };

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      const listDetails = handleGetListWithSetter(source.droppableId);
      const orderResult = handleReOrder(
        listDetails.list,
        source.index,
        destination.index
      );
      listDetails.setList(orderResult);
    } else {
      console.log("handleMove", source, destination);
      handleMove(
        source.droppableId,
        destination.droppableId,
        source,
        destination
      );
    }
  };

  const handleMove = (
    sourceId,
    destId,
    droppableSource,
    droppableDestination
  ) => {
    const sourceListDetails = handleGetListWithSetter(sourceId);
    const destListDetails = handleGetListWithSetter(destId);
    const sourceClone = Array.from(sourceListDetails.list);
    const destClone = Array.from(destListDetails.list);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    console.log(sourceListDetails.list, destListDetails.list);

    sourceListDetails.setList(sourceClone);
    destListDetails.setList(destClone);
  };

  const handleReOrder = (list, sourceIndex, destinationIndex) => {
    const listClone = Array.from(list);
    const [removed] = listClone.splice(sourceIndex, 1);
    listClone.splice(destinationIndex, 0, removed);

    return listClone;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: "5px 0",
    background: "transparent",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: "transparent",
    width: "320px",
  });

  const moveToMainData = () => {
    let yFilter = selectedCheckedQues.map((itemY) => {
      return itemY.id;
    });
    let filteredX = selectedQues.filter((itemX) => !yFilter.includes(itemX.id));
    setselectedQues(filteredX);

    var arr = [];
    selectedCheckedQues.forEach(function (data) {
      var findData = allQues.find((x) => x.id === data.id);
      if (findData === undefined) {
        arr = allQues.concat(selectedCheckedQues);
        setallQues(arr);
      }
    });
    setselectedCheckedQues([]);
  };

  const moveToSelectedData = () => {
    let yFilter = allCheckedQues.map((itemY) => {
      return itemY.id;
    });
    let filteredX = allQues.filter((itemX) => !yFilter.includes(itemX.id));
    setallQues(filteredX);

    var arr = [];
    allCheckedQues.forEach(function (data) {
      var findData = selectedQues.find((x) => x.id === data.id);
      if (findData === undefined) {
        arr = selectedQues.concat(allCheckedQues);
        setselectedQues(arr);
      }
    });
    setallCheckedQues([]);
  };

  const handleCheckedSelectedStore = (item) => {
    // var idObj = selectedQues.find(x => x.id === item.id);
    var selectedObj = selectedCheckedQues.find((x) => x.id === item.id);
    var arr = [];

    if (selectedObj === undefined) {
      setIsChecked(true)
      arr = selectedCheckedQues.concat(item);
      setselectedCheckedQues(arr);
    } else {
      var filterObjs = selectedCheckedQues.filter((x) => x.id !== item.id);
      setselectedCheckedQues(filterObjs);
      setIsChecked(false)

    }
  };

  const handleCheckedAllDataStore = (item) => {
    var selectedObj = allCheckedQues.find((x) => x.id === item.id);
    var arr = [];

    if (selectedObj === undefined) {
      arr = allCheckedQues.concat(item);
      setallCheckedQues(arr);
    } else {
      var filterObjs = allCheckedQues.filter((x) => x.id !== item.id);
      setallCheckedQues(filterObjs);
    }
  };

  const createDroppable = (listName, list) => {
    return (
      <S.DroppableStyle>
        {listName == "selectedQuestions" ? (
          <div className="header">
            <h2 className="text-secondary">
              {createHumanReadableTitle(listName)}{" "}
            </h2>
            {selectedCheckedQues.length !== 0  && <div className="direction" onClick={moveToMainData}>
              <small className="badge bg-primary">
                {" "}
                {selectedCheckedQues.length}
              </small>
              <i className="fa-solid fa-arrow-right"></i>
            </div>}
          </div>
        ) : (
          <div className="header">
            {allCheckedQues.length !== 0  && <div className="direction" onClick={moveToSelectedData}>
              <i className="fa-solid fa-arrow-left"></i>
              <small className="badge bg-primary">
                {" "}
                {allCheckedQues.length}
              </small>
            </div>}

            <h2 className="text-secondary">
              {createHumanReadableTitle(listName)}{" "}
            </h2>
          </div>
        )}

        <Droppable droppableId={listName}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={getListStyle(snapshot.isDragging)}
            >
              {createDraggable(list, listName)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </S.DroppableStyle>
    );
  };

  const createDraggable = (list, listName) => {
    return list.map((item, index) => {
      return (
        <Draggable
          key={item.created_at}
          draggableId={item.created_at}
          index={index}
        >
          {(provided, snapshot) => (
            <div
              {...provided.draggableProps}
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <Card
                item={item}
                ischeck={isCheck}
                allDataStore={handleCheckedAllDataStore}
                selectedStore={handleCheckedSelectedStore}
                title={item.description}
                position={item.position}
                priority="high"
                listName={listName}
              />
            </div>
          )}
        </Draggable>
      );
    });
  };

  const createHumanReadableTitle = (listName) => {
    const result = listName.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  console.log("allCheckedQues", allCheckedQues);

  return (
    <div className="row">
      <div className="col">
        <div className="add-quiz">
          <CForm onSubmit={handleSubmit}>
            <div className="heading">
              <h6>Edit Quiz</h6>
              <hr />
            </div>
            <div className="row">
              <TextFeild
                label="Name"
                hint="Enter a quiz name"
                value={quiz.name}
                onChange={handleChange}
                required={true}
                name="name"
              />
              <Selection
                label="Select Mode"
                options={quizModes}
                displayProperty="name"
                valueProperty="value"
                selectedOption={quiz.mode}
                onSelect={handleChange}
                name="mode"
                req={true}
              />
              <Selection
                label="Status"
                options={statusOptions[quiz.mode || "AUTO"]}
                displayProperty="name"
                valueProperty="value"
                selectedOption={quiz.status}
                onSelect={handleChange}
                name="status"
              />
              <div className="mb-3 col-auto col-lg m-auto">
                <CFormLabel>Active</CFormLabel>
                <CFormCheck
                  name="active"
                  checked={quiz.active}
                  onChange={(e) =>
                    setQuiz({ ...quiz, active: e.target.checked })
                  }
                />
              </div>
            </div>
            <UploadField
              label="Upload Image"
              imageOnly={true}
              onChange={(e) => {
                setQuiz({ ...quiz, image: e.target.files[0] });
                setQuizImage(URL.createObjectURL(e.target.files[0]));
              }}
            />

            {(quizImage || quiz.image) && (
              <CImage
                rounded
                thumbnail
                src={quizImage || quiz.image}
                width={200}
                height={200}
              />
            )}

            <div className="row">
              <TextFeild
                label="Question Timer"
                hint="60"
                value={quiz.qs_timer}
                onChange={handleChange}
                name="qs_timer"
                type="number"
                minimumValue={0}
              />

              {quiz.mode === "AUTO" && (
                <TextFeild
                  label="Waiting Time b/w questions"
                  hint="in seconds"
                  value={quiz.timer_bw_qs}
                  onChange={handleChange}
                  name="timer_bw_qs"
                  type="number"
                  minimumValue={0}
                  required={true}
                />
              )}

              <TextFeild
                label="Question Alert Timer"
                hint="in seconds"
                value={quiz.before_qs_timer}
                onChange={handleChange}
                name="before_qs_timer"
                type="number"
                minimumValue={0}
                required={true}
              />

              {quiz.mode === "MANUAL" && (
                <TextFeild
                  label="Question No"
                  hint="0"
                  value={quiz.question_no}
                  onChange={handleChange}
                  name="question_no"
                  type="number"
                  minimumValue={1}
                />
              )}
            </div>

            <div className="mb-3">
              {quiz.mode === "AUTO" && (
                <CFormCheck
                  inline
                  id="inlineCheckbox1"
                  value={quiz.time_based}
                  checked={quiz.time_based}
                  onChange={(e) => {
                    setQuiz({ ...quiz, time_based: e.target.checked });
                  }}
                  label="Timer Based"
                />
              )}
              <CFormCheck
                inline
                id="inlineCheckbox2"
                value={quiz.show_count_down}
                checked={quiz.show_count_down}
                onChange={(e) => {
                  setQuiz({ ...quiz, show_count_down: e.target.checked });
                }}
                label="Show Count Down"
              />
            </div>

            <div className="row">
              <TextFeild
                label="Join Code"
                hint="1234"
                value={quiz.join_code}
                onChange={(e) =>
                  e.target.value.length <= 4 ? handleChange(e) : null
                }
                name="join_code"
                type="number"
                required={true}
                disabled={true}
              />

              <TextFeild
                label="Quiz Hash"
                hint="Auto Generated"
                value={quiz.quiz_hash}
                onChange={handleChange}
                name="quiz_hash"
                disabled={true}
              />

              <TextFeild
                label="Max Attempt Allowed"
                hint="1"
                value={quiz.max_attempt_count}
                onChange={handleChange}
                name="max_attempt_count"
                type="number"
                required={true}
              />
            </div>

            <div className="row">
              <TextFeild
                label="Quiz Start Date"
                value={getDate(quiz.start_date_time)}
                onChange={(e) => {
                  setQuiz({
                    ...quiz,
                    start_date_time: formetDateTime(e.target.value, startTime),
                  });
                }}
                type="date"
                minimumValue={disablePastDate(new Date(quiz.start_date_time))}
                required={true}
              />

              <TextFeild
                label="Quiz Start Time"
                value={startTime || getTime(quiz.start_date_time)}
                onChange={(e) => {
                  setStartTime(e.target.value);
                  setQuiz({
                    ...quiz,
                    start_date_time: formetDateTime(
                      getDate(quiz.start_date_time),
                      e.target.value
                    ),
                  });
                }}
                type="time"
                required={true}
              />

              <TextFeild
                label="Quiz End Date"
                value={getDate(quiz.end_date_time)}
                onChange={(e) => {
                  setQuiz({
                    ...quiz,
                    end_date_time: formetDateTime(e.target.value, endTime),
                  });
                }}
                type="date"
                minimumValue={disablePastDate(new Date(quiz.start_date_time))}
                required={true}
              />
              <TextFeild
                label="Quiz End Time"
                value={endTime || getTime(quiz.end_date_time)}
                onChange={(e) => {
                  setEndTime(e.target.value);
                  setQuiz({
                    ...quiz,
                    end_date_time: formetDateTime(
                      getDate(quiz.end_date_time),
                      e.target.value
                    ),
                  });
                }}
                type="time"
                required={true}
              />
            </div>

            <div className="row">
              <UploadField
                label="Upload Sponsor Image"
                imageOnly={true}
                onChange={(e) => {
                  setQuiz({ ...quiz, sponsor_logo: e.target.files[0] });
                  setSponserImage(URL.createObjectURL(e.target.files[0]));
                }}
              />

              <TextFeild
                label="Sponsor Name"
                hint="Name"
                value={quiz.sponsor_name}
                onChange={handleChange}
                name="sponsor_name"
              />
            </div>

            {(sponserImage || quiz.sponsor_logo) && (
              <CImage
                rounded
                thumbnail
                src={sponserImage || quiz.sponsor_logo}
                width={200}
                height={200}
              />
            )}

            {categories.hasLoaded && (
              <div className="quiz-question-categories">
                <CForm>
                  <div className="mb-3">
                    <CFormLabel>Filter By Categories</CFormLabel>
                    <div>
                      {categories.state.map((category, index) => (
                        <CFormCheck
                          key={index}
                          inline
                          id={`categoryCheckbox${index}`}
                          value={category.id}
                          label={category.name}
                          onChange={handleCategorySelect}
                          checked={selectedCategoryIds.includes(
                            category.id.toString()
                          )}
                          defaultChecked={selectedCategoryIds.includes(
                            category.id.toString()
                          )}
                        />
                      ))}
                    </div>
                  </div>
                </CForm>
              </div>
            )}

            {/* {questions.hasLoaded && (
              <div className="quiz-questions">
                <CTable>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col"></CTableHeaderCell>
                      <CTableHeaderCell scope="col">Question</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Category</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {filteredQuestions.map((question, index) => (
                      <CTableRow color="light" key={index}>
                        <CTableDataCell width="5%">
                          <CFormCheck
                            id={`questionCheckbox${question.id}`}
                            name="questions"
                            value={question.id}
                            checked={question.checked}
                            onChange={(e) => handleQuestionSelect(e, index)}
                          />
                        </CTableDataCell>
                        <CTableDataCell scope="row" width="65%">
                          {question.description}
                        </CTableDataCell>
                        <CTableDataCell width="30%">
                          {question.category_detail[0]
                            ? question.category_detail[0].name
                            : "-"}
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </div>
            )}
             */}
            {questions.hasLoaded && (
              <S.ListWrapper>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  {createDroppable("selectedQuestions", selectedQues)}
                  {createDroppable("allQuestions", allQues)}
                </DragDropContext>
              </S.ListWrapper>
            )}
            <S.PaginationWrapper>
              <div>
                <CButton type="submit" className="mr-btn btn-md">
                  Save
                </CButton>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <ReactPaginate
                  containerClassName="pagination mt-2"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  breakLabel="..."
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  nextPageRel={questions.state.next}
                  prevPageRel={questions.state.previous}
                  renderOnZeroPageCount={null}
                />
                {/* <small style={{ color: "#7a7a7a" }}>
                    {questions.state.count}{" "}
                    {questions.state.count > 1 ? "questions" : "question"}
                  </small> */}
              </div>
            </S.PaginationWrapper>
          </CForm>
        </div>
      </div>
      {showHelp && (
        <div className="col">
          <HowToCreateQuiz />
        </div>
      )}
    </div>
  );
};

export default UpdateQuiz;
