import { CSidebar, CSidebarNav } from "@coreui/react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { useDispatch, useSelector } from "react-redux";
import { AppSidebarNav } from "./AppSidebarNav";
// sidebar nav config
import {_hidePollnav, _nav} from "../_nav";
import useUser from "../Hooks/user";


const AppSidebar = () => {
  const dispatch = useDispatch();
  const user = useUser();
  const isPollEnabled = user.state.user?.client_details?.settings.poll_enabled;


  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);

  return (
    <CSidebar
      className="sidebar sidebar-fixed sidebar-narrow-unfoldable bg-dark"
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      style={{ zIndex: "1" }}
      onVisibleChange={(visible) => {
        dispatch({ type: "set", sidebarShow: visible });
      }}
    >
      <CSidebarNav className="mt-5 pt-4">
        <SimpleBar>
         {isPollEnabled ? <AppSidebarNav items={_nav} />
        :
        <AppSidebarNav items={_hidePollnav} />
    }
        </SimpleBar>
      </CSidebarNav>
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() =>
          dispatch({ type: "set", sidebarUnfoldable: !unfoldable })
        }
      /> */}
    </CSidebar>
  );
};

export default AppSidebar;
