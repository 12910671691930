import { useLayoutEffect, useEffect, useState } from "react";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";
import { cilMagnifyingGlass } from "@coreui/icons";
import useCategories from "../../Hooks/useCategories";
import useUser from "../../Hooks/user";
import useDateTime from "../../Hooks/useDateTime";
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import ReactPaginate from "react-paginate";
import { ITEMS_PER_PAEG } from "../../config";

const AddCategory = () => {
  const user = useUser();
  const clientId = user.state.user.client;
  const { state, catPost, hasLoaded, searchCat } = useCategories(clientId);
  const { getDateEasy } = useDateTime();
  const [cateoryList, setCateoryList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * ITEMS_PER_PAEG) % state.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffset + ITEMS_PER_PAEG;
    setCateoryList(state.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(state.length / ITEMS_PER_PAEG));
  }, [state, itemOffset]);

  useLayoutEffect(() => {
    document.title = "Add Quiz Category";
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newCategoryName = e.target.categoryName.value;
    if (newCategoryName.length > 0) {
      catPost(newCategoryName);
      e.target.categoryName.value = "";
    }
  };

  return (
    <div className="add-category">
      <div className="heading">
        <h6>Create New Category</h6>
        <hr />
      </div>
      <div className="category-name">
        <CForm onSubmit={handleSubmit}>
          <div className="mb-3">
            <CFormLabel>Name</CFormLabel>
            <CRow className="g-3">
              <CCol sm="auto">
                <CFormInput
                  type="text"
                  placeholder="Enter category name"
                  name="categoryName"
                  required
                />
              </CCol>
              <CCol xs="auto">
                <CButton type="submit">Add</CButton>
              </CCol>
            </CRow>
          </div>
        </CForm>
      </div>
      <div className="heading">
        <h6>Categories</h6>
        <hr />
      </div>
      <CFormLabel>Search Category</CFormLabel>
      <div className="mb-3">
        <CContainer className="ms-0 ps-0">
          <CRow>
            <CCol sm="auto">
              <CInputGroup>
                <CFormInput
                  className="border-end-0"
                  placeholder="Search"
                  aria-label="Search"
                  // value={searchText}
                  onChange={(e) => searchCat(e.target.value)}
                />
                <CInputGroupText className="bg-white">
                  <CIcon icon={cilMagnifyingGlass} />
                </CInputGroupText>
              </CInputGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
      <div className="quiz-questions">
        <CTable>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">Name</CTableHeaderCell>
              <CTableHeaderCell scope="col">Update</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {hasLoaded && (
              <>
                {cateoryList.map((row, index) => (
                  <CTableRow color="light" key={index}>
                    <CTableDataCell>{row.name}</CTableDataCell>
                    <CTableDataCell>
                      {getDateEasy(row.updated_at)}
                    </CTableDataCell>
                    <CTableDataCell>
                      <Link to={"/add-category/" + row.id + "/update"}>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          Edit
                        </button>
                      </Link>
                    </CTableDataCell>
                    <CTableDataCell>
                      <Link to={"/add-category/" + row.id + "/delete"}>
                        <button type="button" className="btn btn-danger btn-sm">
                          Delete
                        </button>
                      </Link>
                    </CTableDataCell>
                  </CTableRow>
                ))}
              </>
            )}
          </CTableBody>
        </CTable>
        <div className="d-flex justify-content-between align-items-center">
          <ReactPaginate
            containerClassName="pagination mt-2"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            breakLabel="..."
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
          />
          <p style={{ color: "#7a7a7a" }}>
            {state.length} {state.length > 1 ? "categories" : "cateory"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
