import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../config";
import getHeaders from "./getHeaders";
import { useNavigate } from "react-router-dom";

export default function useCategories(id) {
  const [state, setState] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const navigate = useNavigate();
  const headers = getHeaders();

  useEffect(() => {
    var cancelHandler = axios.CancelToken.source();
    axios
      .get(
        `${API_URL}/api/v1/category/?client=${id}`,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((res) => {
        setState(res.data);
        setHasLoaded(true);
      })
      .catch((err) => {
        if (!axios.isAxiosError(err)) {
          console.log(err);
        }
      });
    return () => {
      cancelHandler.cancel();
    };
  }, []);

  const getCat = (pageNo = 1) => {
    axios
      .get(`${API_URL}/api/v1/category/?page=${pageNo}&client=${id}`, {
        headers,
      })
      .then((res) => {
        setState(res.data);
        setHasLoaded(true);
      })
      .catch((err) => {
        if (!axios.isAxiosError(err)) {
          console.log(err);
        }
      });
  };

  const getCatById = async (id) => {
    const res = await axios.get(`${API_URL}/api/v1/category/${id}/`, {
      headers,
    });
    return res.data;
  };

  const searchCat = (searchString) => {
    if (searchString === "" && searchString === null) {
      getCat();
    } else {
      axios
        .get(
          `${API_URL}/api/v1/category/?search=${searchString.replaceAll(
            " ",
            "+"
          )}`,
          {
            headers,
          }
        )
        .then((res) => {
          setState(res.data);
          setHasLoaded(true);
        })
        .catch((err) => {
          if (!axios.isAxiosError(err)) {
            console.log(err);
          }
        });
    }
  };

  const catPost = (name) => {
    var cancelHandler = axios.CancelToken.source();
    const catData = {
      name: name,
      client: id,
    };
    axios
      .post(
        `${API_URL}/api/v1/category/?client=${id}`,
        catData,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((res) => {
        setState([...state, res.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateCat = (instance) => {
    var cancelHandler = axios.CancelToken.source();
    const CatData = {
      name: instance.name,
    };
    axios
      .patch(
        `${API_URL}/api/v1/category/${instance.id}/?client=${id}`,
        CatData,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((res) => {
        navigate("/add-category");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Delete = (instance) => {
    var cancelHandler = axios.CancelToken.source();
    axios
      .delete(
        `${API_URL}/api/v1/category/${instance}/?client=${id}`,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((response) => {
        navigate("/add-category");
      })
      .catch((error) => {
        alert("There was an error!", error);
      });
  };

  return {
    state,
    getCat,
    getCatById,
    searchCat,
    catPost,
    UpdateCat,
    Delete,
    hasLoaded,
  };
}
