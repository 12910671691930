import { useLayoutEffect, useEffect, useState } from "react";
import usePolls from "../../Hooks/usePolls";
import { Link, useParams, useNavigate } from "react-router-dom";
import useUser from "../../Hooks/user";

const UpdateChoice = () => {
  const user = useUser();
  const navigate = useNavigate()

  const { id, choice_id } = useParams();
  console.log("choice_id", choice_id)
  const clientId = user.state.user.client;
  const { getChoice, updatePollchoice } = usePolls(clientId);
  // State Variables for the question creation
  const [choice, setChoice] = useState({});
  console.log("chocie", choice)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setChoice({
      ...choice,
      [name]: value,
    });
  };

  const UpdateInstance = (e) => {
    e.preventDefault();
    updatePollchoice(choice);
  };

  useLayoutEffect(() => {
    document.title = "Update";
}, []);

  useEffect(() => {
    getChoice(choice_id).then((data) => setChoice(data));
  }, []);

  return (
    <div className="list-attempts">
      <div className="heading">
        <h6>Edit Choice</h6>
        <hr />
      </div>
      <div className="dataUpdate">
        <form onSubmit={UpdateInstance}>
          <label className="mb-1">Poll Choice Name</label>
          <input
            type="text"
            className="form-control mb-4"
            name="name"
            value={choice.name}
            onChange={handleChange}
            required
          />
      
            <button 
            onClick={() =>  navigate(`/list-polls/${id}/update`)}
            type="button" className="btn btn-danger btn-sm">
              Cancel
            </button>
       
          &nbsp; &nbsp;
          <button type="submit" className="btn btn-primary btn-sm" value={id}>
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateChoice;
