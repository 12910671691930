import { useLayoutEffect, useEffect, useState, useMemo } from "react";
import CIcon from "@coreui/icons-react";
import { cilMagnifyingGlass } from "@coreui/icons";
import useUser from "../../Hooks/user";
import usePolls from "../../Hooks/usePolls";
import { Link } from "react-router-dom";
import { ITEMS_PER_PAEG } from "../../config";
import useDateTime  from "../../Hooks/useDateTime";
import {
  cilClipboard,
  cilCopy,
} from "@coreui/icons";
import {
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import ReactPaginate from "react-paginate";
import { useTable, useRowSelect } from "react-table";
import Checkbox from "../../Components/Checkbox";

const ListPolls = () => {
  const user = useUser();
  const clientId = user.state.user.client;
  const clientViewersAppUrl = user.state.user.client_details.settings.viewer_app_url;
  const [copied, setCopied] = useState(null);
  const { state, UpdatePollbyID } =
  usePolls();
  const [pageCount, setPageCount] = useState(0);
  const { getDateTimeEasy } = useDateTime();

  useEffect(() => {
    setPageCount(Math.ceil(state.count / ITEMS_PER_PAEG));
  }, [state]);

  const handleCheckboxChange = async (poll, property, checked) => {
    // Update only the specified property for the poll
    await UpdatePollbyID(poll, { [property]: !checked });
    // Fetch the updated poll data
    setPageCount(Math.ceil(state.count / ITEMS_PER_PAEG));
  };

  useLayoutEffect(() => {
    document.title = "List Questions";
  }, []);

  return (
    <div className="row">
      <div className="col">
        <div className="list-quiz">
          <div className="heading">
            <h6>List Poll</h6>
            <hr />
          </div>
          <div className="quiz-name">
            <CFormLabel>Search for a poll</CFormLabel>

            <div className="mb-3">
              <CContainer className="ms-0 ps-0">
                <CRow>
                  <CCol sm="auto">
                    <CInputGroup>
                      <CFormInput
                        className="border-end-0"
                        placeholder="Search"
                        aria-label="Search"
                        // value={searchText}
                        // onChange={(e) => searchQuizes(e.target.value)}
                      />
                      <CInputGroupText className="bg-white">
                        <CIcon icon={cilMagnifyingGlass} />
                      </CInputGroupText>
                    </CInputGroup>
                  </CCol>
                </CRow>
              </CContainer>
            </div>
          </div>

          <div className="quiz-questions">
            <CTable responsive className="text-center">
              <CTableHead>
                <CTableRow>
                {/* <CTableHeaderCell scope="col"></CTableHeaderCell> */}
                  <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Poll hash</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Join code</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Active</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Show result Options</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Show total votes</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Created date</CTableHeaderCell>

                </CTableRow>
              </CTableHead>
              <CTableBody>
                {state?.results?.map((poll, index) => {
                      return <CTableRow key={index}>
                       <CTableDataCell>{poll.name}</CTableDataCell>
                       <CTableDataCell>
                          {poll.poll_hash}{" "}
                          {clientViewersAppUrl && <CIcon
                            icon={copied && copied === poll.poll_hash ? cilClipboard : cilCopy}
                            className="copy-icon"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${clientViewersAppUrl}/poll/${poll.poll_hash}`
                              );
                              setCopied(poll.poll_hash);
                              setTimeout(() => {
                                setCopied(null);
                              }, 1000);
                            }}
                          />}
                        </CTableDataCell>
                       <CTableDataCell>{poll.join_code}</CTableDataCell>
                       <CTableDataCell>
                         <input
                           type="checkbox"
                           checked={poll.active}
                          
                         />
                         
                       </CTableDataCell>
                       <CTableDataCell>
                       <input
                        type="checkbox"
                        checked={poll.show_options}
                        // onChange={() => handleCheckboxChange(poll, "show_options", poll.show_options)}
                      />
                         
                       </CTableDataCell>
                       <CTableDataCell>
                         <input
                           type="checkbox"
                           checked={poll.show_total_votes}
                          
                         />
                         
                       </CTableDataCell>
                       <CTableDataCell>{getDateTimeEasy(poll.created_at)}</CTableDataCell>
                       <CTableDataCell>
                      <Link to={"/list-polls/" + poll.id + "/update"}>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          Edit
                        </button>
                      </Link>
                      </CTableDataCell>
                      <CTableDataCell>
                      <Link to={"/list-polls/" + poll.id + "/delete"}>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                        >
                          Delete
                        </button>
                      </Link>
                      </CTableDataCell>
                     </CTableRow>
                })}
              </CTableBody>
            </CTable>
            <div className="d-flex justify-content-between align-items-center">
              <ReactPaginate
                containerClassName="pagination mt-2"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                breakLabel="..."
                // onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
              />
              <p style={{ color: "#7a7a7a" }}>
                {state.count} {state.count > 1 ? "polls" : "poll"}
              </p>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default ListPolls;
