import { CButton, CForm, CImage } from "@coreui/react";
import { useEffect, useState } from "react";
import useCategories from "../../Hooks/useCategories";
import useUser from "../../Hooks/user";
import useQuestions from "../../Hooks/useQuestions";
import "./styles.scss";
import Selection from "../../Components/selection";
import TextFeild from "../../Components/textFeild";
import TextAreaField from "../../Components/textAreaField";
import UploadField from "../../Components/uploadField";
import { Link, useParams } from "react-router-dom";

const UpdateQuestion = () => {
  const user = useUser();
  const { id } = useParams();
  const clientId = user.state.user.client;
  const categories = useCategories(clientId);
  const { UpdateQues, getQuestionById } = useQuestions(clientId);
  const [sponsorImage, setSponsorImage] = useState(null);
  // State Variables for the question creation
  const [question, setQuestion] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(question);
    UpdateQues(question);
  };

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setQuestion({
      ...question,
      [name]: value,
    });
  };

  useEffect(() => {
    document.title = "Edit Question";
    getQuestionById(id).then((ques) => setQuestion(ques));
  }, []);

  console.log("question.options",question)

  return (
    <div className="add-question">
      <CForm onSubmit={handleSubmit}>
        <div className="heading">
          <h6>Edit Question</h6>
          <hr />
        </div>
        <div className="inline-fields">
          <Selection
            label="Select category"
            options={categories.state}
            displayProperty="name"
            valueProperty="id"
            selectedOption={question.category}
            onSelect={(e) =>
              setQuestion({
                ...question,
                category: e.target.value,
              })
            }
            required={true}
          />
        </div>
        <div className="inline-fields">
          <TextFeild
            label="Description"
            hint="Whats is the name of our country?"
            value={question.description}
            onChange={handleChange}
            name="description"
            required={true}
          />
          <TextFeild
            label="Hint"
            hint="starts from P"
            value={question.hint}
            onChange={handleChange}
            name="hint"
          />
        </div>
        <div className="inline-fields">
          <UploadField
            label="Upload Sponsor Image"
            imageOnly={true}
            onChange={(e) => {
              setQuestion({ ...question, image: e.target.files[0] });
              setSponsorImage(URL.createObjectURL(e.target.files[0]));
            }}
            name="image"
          />

          {(sponsorImage || question.image) && (
            <CImage
              rounded
              thumbnail
              src={sponsorImage || question.image}
              width={200}
              height={200}
            />
          )}
        </div>
        <div className="quiz-details">
          {question.options && (
            <div className="Options">
              {Object.keys(question.options).map((key) => (
                <TextFeild
                  key={key}
                  label={`Option ${key.toUpperCase()}`}
                  value={question.options[key]}
                  onChange={(e) =>
                    setQuestion({
                      ...question,
                      options: {
                        ...question.options,
                        [key]: e.target.value,
                      },
                    })
                  }
                  required={true}
                />
              ))}
            </div>
          )}

          <div className="correct-option">
            <TextFeild
              label="Correct Option"
              hint="a"
              value={question.correct_option}
              onChange={handleChange}
              name="correct_option"
              required={true}
            />
          </div>
        </div>
        <Link to="/list-questions">
          <button type="button" className="btn btn-danger btn-sm">
            Cancel
          </button>
        </Link>
        &nbsp; &nbsp;
        <button type="submit" className="btn btn-primary btn-sm" value={id}>
          Update
        </button>
      </CForm>
    </div>
  );
};

export default UpdateQuestion;
