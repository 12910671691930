import { cilBell, cilEnvelopeClosed, cilMenu } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CAvatar,
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CHeader,
  CHeaderBrand,
  // CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavItem,
  CNavLink,
} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import useUser from "../Hooks/user";
import Logo from "../assets/icons/QuizAppLogo.png";

const AppHeader = () => {
  const userMg = useUser();
  const navigate = useNavigate();
  const clientLogo = userMg.state?.user?.client_details?.settings?.business_logo_url
  const username = userMg.state.user.username.toUpperCase();

  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);

  return (
    <CHeader position="sticky" className="bg-dark mb-4 border-0">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
        >
          <CIcon className="text-white" icon={cilMenu} size="lg" />
        </CHeaderToggler>
        {/* <CHeaderNav className="d-none d-md-flex">
          <CNavItem>
            <CNavLink
              to="/dashboard"
              className="text-white"
              component={NavLink}
              style={{ marginLeft: "5px" }}
            >
              Dashboard
            </CNavLink>
          </CNavItem>
        </CHeaderNav> */}
        <CHeaderBrand className="mx-auto" to="/">
          <img src={clientLogo} alt="" width={90} />
        </CHeaderBrand>

        <CHeaderNav>
          {/* <CNavItem>
            <CNavLink className="text-white">
              <CIcon icon={cilBell} size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink className="text-white">
              <CIcon icon={cilEnvelopeClosed} size="lg" />
            </CNavLink>
          </CNavItem> */}
          <CDropdown
            variant="nav-item"
            className="position-relative"
            popper={false}
          >
            <CDropdownToggle>
              <CAvatar className="account-initial text-white">
                {username.charAt(0)}
              </CAvatar>
            </CDropdownToggle>
            <CDropdownMenu style={{ left: "-220%" }}>
              <CDropdownItem
                href="#"
                onClick={async () => {
                  await userMg.actions.logout();
                  navigate("/login");
                }}
              >
                Logout
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </CHeaderNav>
      </CContainer>
      {/* <CHeaderDivider /> */}
      {/* <CContainer fluid>
        <AppBreadcrumb />
      </CContainer> */}
    </CHeader>
    // <CHeader position="sticky" className="bg-dark full-width">
    //   <CContainer fluid>
    //     <CHeaderNav className="d-none d-md-flex me-auto full-width">
    //       <CHeaderToggler className="ps-1 text-white" onClick={handleSetVisible}>
    //         <CIcon icon={cilMenu} size="lg" />
    //       </CHeaderToggler>
    //       <CNavItem>
    //         <CNavLink
    //           className="text-white"
    //           style={{ marginLeft: "10px" }}
    //           onClick={() => navigate("/dashboard")}
    //         >
    //           Dashboard
    //         </CNavLink>
    //       </CNavItem>
    //       <CHeaderNav style={{ marginLeft: "auto" }}>
    //         <CNavItem>
    //           <CNavLink className="text-white" onClick={() => navigate("/")}>
    //             <CIcon icon={cilBell} />
    //           </CNavLink>
    //         </CNavItem>

    //         <CNavItem>
    //           <CNavLink className="text-white" onClick={() => navigate("/")}>
    //             <CIcon icon={cilEnvelopeClosed} />
    //           </CNavLink>
    //         </CNavItem>

    //         <CNavItem
    //           style={{
    //             width: "2.5rem",
    //             height: "2.5rem",
    //             fontSize: "1rem",
    //           }}
    //         >
    //           <CNavLink
    //             className="text-white account-initial"
    //             onClick={() => navigate("/")}
    //           >
    //             {username.charAt(0)}
    //           </CNavLink>
    //         </CNavItem>
    //       </CHeaderNav>
    //     </CHeaderNav>
    //   </CContainer>
    // </CHeader>
  );
};

export default AppHeader;
