import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import useUser from "../../Hooks/user";
import axios from "axios";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const userMg = useUser();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    document.title = "Dashboard Login";
  }, []);

  async function loginSubmit(e) {
    e.preventDefault();
    if (email && password) {
      setDisable(true);
      try {
        await userMg.actions.login({ email, password });
        setDisable(false);
        navigate("/dashboard");
      } catch (err) {
        console.log("error", error)
        if (axios.isAxiosError(err)) {
          let data = err.response.data;
          if ("non_field_errors" in data) {
            let msg = data["non_field_errors"][0];
            if (msg === "Unable to log in with provided credentials.") {
              setError("Email or Password are incorrect");
              setErrorVisible(true);
            } else {
              setError(msg);
              setErrorVisible(true);
            }
            setDisable(false);
            setPassword("");
          }
        }
      }
    }
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={loginSubmit}>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">
                      Sign In to your account
                    </p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Email"
                        autoComplete="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={disable}
                        required
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={disable}
                        required
                      />
                    </CInputGroup>
                    <CAlert
                      className="mt-3"
                      color="danger"
                      dismissible
                      visible={errorVisible}
                      onClose={() => setErrorVisible(false)}
                    >
                      {error}
                    </CAlert>
                    <CRow>
                      <CCol xs={6}>
                        <CButton
                          color="primary"
                          className="px-4"
                          type="submit"
                          disabled={disable}
                        >
                          Login
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-center">
                        <CButton
                          color="link"
                          className="px-0"
                          disabled={disable}
                        >
                          <Link
                            to="/reset/password"
                            style={{ color: "#2ba7dc", textDecoration: "none", fontSize: "0.9rem"}}
                          >
                          <i className="fa-solid fa-lock"></i> Forgot Password
                          </Link>
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
