import { CFormLabel, CFormTextarea } from "@coreui/react";
import React from "react";

const TextAreaField = ({
  label,
  name,
  hint,
  value,
  onChange,
  numberOfRows,
  required = false,
}) => {
  return (
    <div className="mb-3">
      <CFormLabel>{label}</CFormLabel>
      <CFormTextarea
        name={name}
        rows={numberOfRows}
        placeholder={hint}
        value={value}
        onChange={onChange}
        required={required}
      ></CFormTextarea>
    </div>
  );
};

export default TextAreaField;
