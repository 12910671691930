import styled from "styled-components";
import { devices } from "../../../utils/styles/devices";


export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  color: #98a2b3;
  font-size: 14px;
  text-align: start;
  background: #fcfcfd;
  box-shadow: 0px 0.5px 2px rgba(16, 24, 40, 0.3);
  border-radius: 2px;

  @media ${devices.mobileS} {
    padding: 5px;
  }

  @media ${devices.laptop} {
    padding: 10px;
    width:31rem;


  }

 
  


  h2 {
    color: #1d2939;
    font-size: 13px;
    font-weight: bold;
    text-wrap: break-word;
  }
  small{
    text-wrap: break-word;
    font-size: 13px;

  }

  .card-header {
    display: flex;
    flex-direction: row;
    gap:0.3rem;
    justify-content:start;
    align-items: center;
  }

  .card-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }

  .dot {
    height: 4px;
    width: 4px;
    background-color: #c4c4c4;
    border-radius: 50%;
    display: inline-block;
  }

  .card-body {
    display: flex;
    flex-direction: fle;
    gap: 0.2rem;

    p {
      margin: 0;
    }
  }

  .card-tags {
    display: flex;
    width: 100%;
    gap: 0.2rem;
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem;

    .assigned {
      display: flex;
      gap: 0.2rem;
    }

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

export const Tag = styled.label`
  padding: 6px 12px;
  background: #f2f4f7;
  color: #475467;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  border-radius: 6px;
  text-align: center;
  margin-right: 8px;
`;


