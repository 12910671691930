import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyB5P8QGnc7ObYoeA1FmXtD6pkLa0M5o6to",
  authDomain: "quizboard-admin.firebaseapp.com",
  databaseURL:
    "https://quizboard-admin-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "quizboard-admin",
  storageBucket: "quizboard-admin.appspot.com",
  messagingSenderId: "762558123310",
  appId: "1:762558123310:web:f78403e064d0171d6e3ebf",
  measurementId: "G-G8LNRQHW0L",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Get a reference to the database service
const db = getDatabase(app);

export { app, db };
