import { useLayoutEffect, useEffect, useState } from "react";
import CIcon from "@coreui/icons-react";
import { cilMagnifyingGlass } from "@coreui/icons";
import useViewers from "../../Hooks/useViewers";
import {
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import ReactPaginate from "react-paginate";
import { ITEMS_PER_PAEG } from "../../config";
import QuizSelector from "../ListAttempts/components/QuizSelector";

const ListViewers = () => {
  const { state, getViewers, searchViewers, getViewersByQuizId } = useViewers();
  const [pageCount, setPageCount] = useState(0);

  const handlePageClick = (event) => {
    getViewers(event.selected + 1);
  };

  useLayoutEffect(() => {
    document.title = "List Viewers";
  }, []);

  useEffect(() => {
    setPageCount(Math.ceil(state.count / ITEMS_PER_PAEG));
  }, [state]);

  return (
    <div className="list-attempts">
      <div className="heading">
        <h6>List Viewers</h6>
        <hr />
      </div>
      <div className="attempt-name">
        <div className="mb-3">
          <CContainer className="ms-0 ps-0">
            <CRow className="justify-content-between align-items-center">
              <CRow className="w-75">
                <CCol sm="auto">
                  <QuizSelector
                    onChange={(e) => getViewersByQuizId(e.target.value)}
                  />
                </CCol>
                <CCol sm="auto">
                  <CFormLabel>Search</CFormLabel>
                  <CInputGroup>
                    <CFormInput
                      className="border-end-0"
                      placeholder="Search"
                      aria-label="Search"
                      onChange={(e) => searchViewers(e.target.value)}
                    />
                    <CInputGroupText className="bg-white">
                      <CIcon icon={cilMagnifyingGlass} />
                    </CInputGroupText>
                  </CInputGroup>
                </CCol>
              </CRow>
            </CRow>
          </CContainer>
        </div>
      </div>

      <div className="quiz-viewers">
        <CTable responsive>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">User</CTableHeaderCell>
              <CTableHeaderCell scope="col">Full Name</CTableHeaderCell>
              <CTableHeaderCell scope="col">Email</CTableHeaderCell>
              <CTableHeaderCell scope="col">Mobile</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {state.results.map((row, index) => (
              <CTableRow color="light" key={index}>
                <CTableDataCell>{row.user_detail.username}</CTableDataCell>
                <CTableDataCell>{`${row.user_detail.first_name} ${row.user_detail.last_name}`}</CTableDataCell>
                <CTableDataCell>{row.user_detail.email}</CTableDataCell>
                <CTableDataCell>{row.mobile}</CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
        <div className="d-flex justify-content-between align-items-center">
          <ReactPaginate
            containerClassName="pagination mt-2"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            breakLabel="..."
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            nextPageRel={state.next}
            prevPageRel={state.previous}
            renderOnZeroPageCount={null}
          />
          <p style={{ color: "#7a7a7a" }}>
            Showing {state.results.length} of {state.count}{" "}
            {state.count > 1 ? "viewers" : "viewer"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ListViewers;
