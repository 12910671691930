import { useLayoutEffect, useEffect, useState } from "react";
import CIcon from "@coreui/icons-react";
import { cilMagnifyingGlass } from "@coreui/icons";
import {
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import useUser from "../../Hooks/user";
import useQuizes from "../../Hooks/useQuizes";
import HowToListQuiz from "./howTo";
import ReactPaginate from "react-paginate";
import "./styles.scss";
import { ITEMS_PER_PAEG } from "../../config";
import QuizRow from "./quizRow";

const ListQuiz = ({ showHelp }) => {
  const user = useUser();
  const clientId = user.state.user.client;
  const { state, getQuizes, searchQuizes } = useQuizes(clientId);


  const [pageCount, setPageCount] = useState(0);

  const handlePageClick = (event) => {
    getQuizes(event.selected + 1);
  };

  useEffect(() => {
    setPageCount(Math.ceil(state.count / ITEMS_PER_PAEG));
  }, [state]);

  useLayoutEffect(() => {
    document.title = "List Quiz";
  }, []);

  return (
    <div className="row">
      <div className="col">
        <div className="list-quiz">
          <div className="heading">
            <h6>List Quiz</h6>
            <hr />
          </div>
          <div className="quiz-name">
            <CFormLabel>Select quiz to change</CFormLabel>

            <div className="mb-3">
              <CContainer className="ms-0 ps-0">
                <CRow>
                  <CCol sm="auto">
                    <CInputGroup>
                      <CFormInput
                        className="border-end-0"
                        placeholder="Search"
                        aria-label="Search"
                        // value={searchText}
                        onChange={(e) => searchQuizes(e.target.value)}
                      />
                      <CInputGroupText className="bg-white">
                        <CIcon icon={cilMagnifyingGlass} />
                      </CInputGroupText>
                    </CInputGroup>
                  </CCol>
                </CRow>
              </CContainer>
            </div>
          </div>

          <div className="quiz-questions">
            <CTable responsive className="text-center">
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Questions</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Mode</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Status</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Start Time</CTableHeaderCell>
                  <CTableHeaderCell scope="col">End Time</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Quiz Hash</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Edit</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Delete</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Reset Attempts</CTableHeaderCell>

                </CTableRow>
              </CTableHead>
              <CTableBody>
                {state.results.map((quiz, index) => {
                  return <QuizRow quiz={quiz} />;
                })}
              </CTableBody>
            </CTable>
            <div className="d-flex justify-content-between align-items-center">
              <ReactPaginate
                containerClassName="pagination mt-2"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                breakLabel="..."
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
              />
              <p style={{ color: "#7a7a7a" }}>
                {state.count} {state.count > 1 ? "quizzes" : "quiz"}
              </p>
            </div>
          </div>
        </div>
      </div>
      {showHelp && (
        <div className="col">
          <HowToListQuiz />
        </div>
      )}
    </div>
  );
};

export default ListQuiz;
