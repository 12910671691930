import { CCol, CFormInput, CFormLabel } from "@coreui/react";
import React from "react";

const TextFeild = ({
  label,
  name,
  hint,
  value,
  initialValue,
  onChange,
  required = false,
  disabled = false,
  type = "text",
  minimumValue,
}) => {
  return (
    <CCol className="mb-3 col-auto col-lg">
      <CFormLabel>{label}</CFormLabel>
      <CFormInput
        name={name}
        type={type}
        placeholder={hint}
        value={value}
        defaultValue={initialValue}
        onChange={onChange}
        required={required}
        disabled={disabled}
        min={minimumValue}
      />
    </CCol>
  );
};

export default TextFeild;
