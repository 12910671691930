import React from "react";
import { Link } from "react-router-dom";

const HowToCreateQuiz = () => {
  return (
    <div className="how-to">
      <div className="heading">
        <h6>Help</h6>
        <hr />
      </div>
      <b>Steps required to conduct a quiz</b>
      <ol>
        <li>
          Register some{" "}
          <Link className="link-primary" to="/add-category">
            categories.
          </Link>
        </li>
        <li>
          Add some{" "}
          <Link className="link-primary" to="/add-question">
            questions
          </Link>{" "}
          belonging to those categories.
        </li>
        <li>
          Create a quiz by filling up the form &amp; checkmarking the questions
          you want to include in the quiz.
        </li>
      </ol>
      <b>Fields Info</b>
      <ul>
        <li>
          <b>Name:</b> It is te name of te quiz.
        </li>
        <li>
          <b>Mode:</b> This indicates the current behavior of te quiz.
          <br />
          There are two modes:
          <ul>
            <li>
              <b>MANUAL:</b> Admin decides when to change the status of te quiz.
            </li>
            <li>
              <b>AUTO:</b> System automatically changes the status a/c to quiz
              settings.
            </li>
          </ul>
        </li>
        <li>
          <b>Status:</b> Set initial/current state of te quiz.
        </li>
        <li>
          <b>Timer:</b> Set time allowed to attempt the quiz.
        </li>
        <li>
          <b>Question no:</b> Set initial/current question number of te quiz.
        </li>
        <li>
          <b>Join Code:</b> Set join code for te quiz.
        </li>
        <li>
          <b>Quiz Hash:</b> This is a unique hash of te quiz (auto generated).
        </li>
        <li>
          <b>Start &amp; End Date/Time:</b> Set start &amp; date and time.
        </li>
      </ul>
    </div>
  );
};

export default HowToCreateQuiz;
