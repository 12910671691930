import { useEffect, forwardRef, useRef } from "react";

const Checkbox = forwardRef(({ interminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = interminate;
  }, [resolvedRef, interminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

export default Checkbox;
