import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../config";
import getHeaders from "./getHeaders";

export default function useViewers(id) {
  const [state, setState] = useState({ results: [] });
  const [hasLoaded, setHasLoaded] = useState(false);

  const headers = getHeaders();

  // useEffect(() => {
  //   var cancelHandler = axios.CancelToken.source();
  //   axios
  //     .get(
  //       `${API_URL}/api/v1/profiles/viewer/`,
  //       {
  //         headers,
  //       },
  //       {
  //         cancelToken: cancelHandler.token,
  //       }
  //     )
  //     .then((res) => {
  //       setState(res.data);
  //       setHasLoaded(true);
  //     })
  //     .catch((err) => {
  //       if (!axios.isAxiosError(err)) {
  //         console.log(err);
  //       }
  //     });
  //   return () => {
  //     cancelHandler.cancel();
  //   };
  // }, []);

  const getViewers = (pageNo = 1) => {
    axios
      .get(`${API_URL}/api/v1/profiles/viewer/?page=${pageNo}`, {
        headers,
      })
      .then((res) => {
        setState(res.data);
        setHasLoaded(true);
      })
      .catch((err) => {
        if (!axios.isAxiosError(err)) {
          console.log(err);
        }
      });
  };

  const getViewersByQuizId = (quizId, pageNo = 1) => {
    axios
      .get(`${API_URL}/api/v1/profiles/viewer/?quiz=${quizId}&page=${pageNo}`, {
        headers,
      })
      .then((res) => {
        setState(res.data);
        setHasLoaded(true);
      })
      .catch((err) => {
        if (!axios.isAxiosError(err)) {
          console.log(err);
        }
      });
  };

  const searchViewers = (searchString) => {
    if (searchString === "" && searchString === null) {
      getViewers();
    } else {
      axios
        .get(
          `${API_URL}/api/v1/profiles/viewer/?search=${searchString.replaceAll(
            " ",
            "+"
          )}`,
          {
            headers,
          }
        )
        .then((res) => {
          setState(res.data);
          setHasLoaded(true);
        })
        .catch((err) => {
          if (!axios.isAxiosError(err)) {
            console.log(err);
          }
        });
    }
  };

  return { state, getViewers, getViewersByQuizId, hasLoaded, searchViewers };
}
