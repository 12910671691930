import { useLayoutEffect, useEffect, useState } from "react";
import usePolls from "../../Hooks/usePolls";
import { Link, useParams } from "react-router-dom";
import useUser from "../../Hooks/user";
import UploadField from "../../Components/uploadField";

const AddPoll = () => {
  const user = useUser();

  const clientId = user.state.user.client;
  const {createPoll } = usePolls();
  // State Variables for the question creation
  const [poll, setPoll] = useState({
    name: "",
    join_code: "",
    show_options: false,
    show_total_votes: false,
    qr_image:null,
    client:clientId,
    // Add other properties as needed
  });
  console.log("qr image", poll)

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    setPoll({
      ...poll,
      [name]: inputValue,
    });
  };

  const createInstance = (e) => {
    e.preventDefault();
    console.log("creating poll", poll)
    createPoll(poll);
  };

  useLayoutEffect(() => {
    document.title = "Create Poll";
  }, []);

  
  return (
    <div className="list-attempts">
      <div className="heading">
        <h6>Add Poll</h6>
        <hr />
      </div>
      <div className="dataUpdate">
        <form onSubmit={createInstance}>
          <label className="mb-1">Poll Name</label>
          <input
            type="text"
            className="form-control mb-4"
            name="name"
            value={poll.name}
            onChange={handleChange}
            required
          />
           <label className="mb-1">Poll Join code</label>
          <input
            type="text"
            className="form-control mb-4"
            name="join_code"
            value={poll.join_code}
            onChange={handleChange}
            required
          />
        <div className="d-flex gap-3 mb-3">
          <div className="d-flex gap-1">
          <input
              type="checkbox"
              name="show_options"
              checked={poll.show_options}
              onChange={handleChange}

            />
          
        <label className="mb-1">Show result options</label>
           
          </div>

         <div className="d-flex gap-1">
         <input
              type="checkbox"
              name="show_total_votes"
              checked={poll.show_total_votes}
              onChange={handleChange}
       
            />
         <label className="mb-1">Show total votes count</label>
       
         </div>

        </div>

        <UploadField
            label="Poll QR Image "
            imageOnly={true}
            name="qr_image"
            onChange={(e) => {
              setPoll({
                ...poll,
                qr_image: e.target.files[0],
              });
            }}
          />
          <Link to="/list-polls">
            <button type="button" className="btn btn-danger btn-sm">
              Cancel
            </button>
          </Link>
          &nbsp; &nbsp;
          <button type="submit" className="btn btn-primary btn-sm">
            Create Poll
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddPoll;
