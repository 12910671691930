import { useLayoutEffect, useEffect, useState, useMemo } from "react";
import CIcon from "@coreui/icons-react";
import { cilMagnifyingGlass } from "@coreui/icons";
import useUser from "../../Hooks/user";
import useQuestions from "../../Hooks/useQuestions";
import { Link } from "react-router-dom";
import { ITEMS_PER_PAEG } from "../../config";

import {
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import ReactPaginate from "react-paginate";
import { useTable, useRowSelect } from "react-table";
import Checkbox from "../../Components/Checkbox";

const ListQuestions = () => {
  const user = useUser();
  const clientId = user.state.user.client;
  const { state, getQuestions, bulkDeleteQuestions, searchQuestions } =
    useQuestions(clientId);
  const [pageCount, setPageCount] = useState(0);

  const data = useMemo(() => {
    return state.results.map((question) => ({
      id: question.id,
      description: question.description,
      options: question.options,
      correct_option: question.correct_option,
      category: question.category_detail[0]?.name,
    }));
  }, [state]);

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Question",
        accessor: "description",
      },
      {
        Header: "Options",
        accessor: "options",
      },
      {
        Header: "Correct Option",
        accessor: "correct_option",
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Edit",
        Cell: ({ row: { original } }) => (
          <Link to={"/list-questions/" + original.id + "/update"}>
            <button type="button" className="btn btn-primary btn-sm">
              Edit
            </button>
          </Link>
        ),
      },
      {
        Header: "Delete",
        Cell: ({ row: { original } }) => (
          <Link to={"/list-questions/" + original.id + "/delete"}>
            <button type="button" className="btn btn-danger btn-sm">
              Delete
            </button>
          </Link>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data }, useRowSelect, (hooks) => {
    hooks.visibleColumns.push((columns) => {
      return [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
        },
        ...columns,
      ];
    });
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = tableInstance;

  const handlePageClick = (event) => {
    getQuestions(event.selected + 1);
  };

  useEffect(() => {
    setPageCount(Math.ceil(state.count / ITEMS_PER_PAEG));
  }, [state]);

  useLayoutEffect(() => {
    document.title = "List Questions";
  }, []);

  return (
    <div className="list-questions">
      <div className="heading">
        <h6>List Questions</h6>
        <hr />
      </div>
      <div className="question-name">
        <CFormLabel>Search</CFormLabel>
        <div className="mb-3">
          <CContainer className="ms-0 ps-0">
            <CRow>
              <CCol sm="auto">
                <CInputGroup>
                  <CFormInput
                    className="border-end-0"
                    placeholder="Search"
                    aria-label="Search"
                    // value={searchText}
                    onChange={(e) => searchQuestions(e.target.value)}
                  />
                  <CInputGroupText className="bg-white">
                    <CIcon icon={cilMagnifyingGlass} />
                  </CInputGroupText>
                </CInputGroup>
              </CCol>
            </CRow>
          </CContainer>
        </div>

        {selectedFlatRows.length > 0 && (
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() =>
              bulkDeleteQuestions(
                selectedFlatRows.map(({ original: { id } }) => parseInt(id))
              )
            }
          >
            {`Delete Selected (${selectedFlatRows.length})`}
          </button>
        )}
      </div>

      <div className="question-list">
        <CTable responsive {...getTableProps()}>
          <CTableHead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                <CTableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <CTableHeaderCell scope="col" {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </CTableHeaderCell>
                  ))}
                </CTableRow>
              ))
            }
          </CTableHead>
          <CTableBody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <CTableRow color="light" key={index} {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <CTableDataCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </CTableDataCell>
                  ))}
                </CTableRow>
              );
            })}
            {/* {data.map((row, index) => (
              <CTableRow color="light" key={index}>
                <CTableDataCell width="5%">{row.id}</CTableDataCell>
                <CTableDataCell>{row.description}</CTableDataCell>
                <CTableDataCell>{row.options}</CTableDataCell>
                <CTableDataCell>
                  {row.correct_option.toUpperCase()}
                </CTableDataCell>
                <CTableDataCell>{row.category}</CTableDataCell>
                <CTableDataCell>
                  <Link to={"/list-questions/" + row.id + "/update"}>
                    <button type="button" className="btn btn-primary btn-sm">
                      Edit
                    </button>
                  </Link>
                </CTableDataCell>
                <CTableDataCell>
                  <Link to={"/list-questions/" + row.id + "/delete"}>
                    <button type="button" className="btn btn-danger btn-sm">
                      Delete
                    </button>
                  </Link>
                </CTableDataCell>
              </CTableRow>
            ))} */}
          </CTableBody>
        </CTable>
        <div className="d-flex justify-content-between align-items-center">
          <ReactPaginate
            containerClassName="pagination mt-2"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            breakLabel="..."
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            nextPageRel={state.next}
            prevPageRel={state.previous}
            renderOnZeroPageCount={null}
          />
          <p style={{ color: "#7a7a7a" }}>
            {state.count} {state.count > 1 ? "questions" : "question"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ListQuestions;
