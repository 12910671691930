import { CFormLabel, CFormSelect } from "@coreui/react";
import React from "react";

const Selection = ({
  label,
  name,
  options,
  displayProperty,
  valueProperty,
  selectedOption,
  onSelect,
  req,
}) => {
  return (
    <div className="mb-3 col-auto col-lg">
      <CFormLabel>{label}</CFormLabel>
      <CFormSelect
        aria-label="Select"
        // required={required}
        required={req}
        value={selectedOption}
        onChange={onSelect}
        name={name}
        defaultValue={null}
      >
        <option value="" disabled selected>
          Select
        </option>
        {options.length > 0
          ? options.map((option, index) => (
              <option
                key={index}
                value={valueProperty ? option[valueProperty] : option}
              >
                {displayProperty ? option[displayProperty] : option}
              </option>
            ))
          : // <option value={null}>Select</option>
            ""}
      </CFormSelect>
    </div>
  );
};

export default Selection;
