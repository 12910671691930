import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../config";
import { useNavigate } from "react-router-dom";
import getHeaders from "./getHeaders";



function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}

 export function jsonToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

export default function useBussinessSetting(id) {
  const [state, setState] = useState({});
  const [hasLoaded, setHasLoaded] = useState(false);
  const navigate = useNavigate();

  const headers = getHeaders();

  var cancelHandler = axios.CancelToken.source();

async function fetchClientData() {
  try {
    const response = await axios.get(
      `${API_URL}/api/v1/profiles/client/`,
      {
        headers,
        cancelToken: cancelHandler.token,
      }
    );

    console.log(response.data[0]);
    setState(response.data[0]);
    setHasLoaded(true);
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      console.log(error);
    }
  }
}
  useEffect(() => {
    fetchClientData()
    return () => {
      cancelHandler.cancel();
    };
  }, []);

  const updateBussinessSetting = (instance) => {
    console.log("instance", instance)
    var cancelHandler = axios.CancelToken.source();
    axios
      .patch(
        `${API_URL}/api/v1/profiles/client/${id}/`,
        jsonToFormData(instance),
        // instance,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((response) => {
        fetchClientData()
        console.log("updare response => ", response);
      
      })
      .catch((error) => {
        alert("There was an error!", error);
      });
  };

  const Delete = (instance) => {
    var cancelHandler = axios.CancelToken.source();
    axios
      .delete(
        `${API_URL}/api/v1/attempts/${instance}/?client=${id}`,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((response) => {
        navigate("/quiz-report");
      })
      .catch((error) => {
        alert("There was an error!", error);
      });
  };

 
  return { state, Delete, updateBussinessSetting, hasLoaded  };
}
