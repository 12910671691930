import { useLayoutEffect, useEffect, useState } from "react";
import useCategories from "../../Hooks/useCategories";
import { Link, useParams } from "react-router-dom";
import useUser from "../../Hooks/user";

const UpdateCat = () => {
  const user = useUser();
  const { id } = useParams();
  const clientId = user.state.user.client;
  const { UpdateCat, getCatById } = useCategories(clientId);
  // State Variables for the question creation
  const [category, setCategory] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory({
      ...category,
      [name]: value,
    });
  };

  const UpdateInstance = (e) => {
    e.preventDefault();
    UpdateCat(category);
  };

  useLayoutEffect(() => {
    document.title = "Update";
  }, []);

  useEffect(() => {
    getCatById(id).then((cat) => setCategory(cat));
  }, []);

  return (
    <div className="list-attempts">
      <div className="heading">
        <h6>Edit Category</h6>
        <hr />
      </div>
      <div className="dataUpdate">
        <form onSubmit={UpdateInstance}>
          <label className="mb-1">Category Name</label>
          <input
            type="text"
            className="form-control mb-4"
            name="name"
            value={category.name}
            onChange={handleChange}
            required
          />
          <Link to="/add-category">
            <button type="button" className="btn btn-danger btn-sm">
              Cancel
            </button>
          </Link>
          &nbsp; &nbsp;
          <button type="submit" className="btn btn-primary btn-sm" value={id}>
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateCat;
