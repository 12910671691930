import React, { Suspense, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.scss";
import Loading from "./Components/Loading";
import useUser from "./Hooks/user";
import DefaultLayout from "./Layout/DefaultLayout";
import AddQuiz from "./screen/AddQuiz";
import ListQuiz from "./screen/ListQuiz";
import AddCategory from "./screen/AddCategory";
import UpdateCat from "./screen/AddCategory/UpdateCat";
import CatDelete from "./screen/AddCategory/DeleteCat";
import AddQuestion from "./screen/AddQuestion";
import ListQuestions from "./screen/ListQuestions";
import ListPolls from "./screen/ListPolls";
import UpdatePoll from "./screen/ListPolls/UpdatePoll";
import AddPoll from "./screen/ListPolls/AddPoll";
import DeletePoll from "./screen/ListPolls/DeletePoll";
import UpdateChoice from "./screen/ListPolls/UpdatePollChoice";
import DeletePollChoice from "./screen/ListPolls/DeletePollChoice";

import DeleteQues from "./screen/AddQuestion/DeleteQues";
import ListAttempts from "./screen/ListAttempts";
import QuizDelete from "./screen/AddQuiz/QuizDelete";
import ListViewers from "./screen/ListViewers";
import BussinessSetting from "./screen/BussinessSetting";
import UpdateQuestion from "./screen/AddQuestion/updateQuestion";
import UpdateQuiz from "./screen/AddQuiz/updateQuiz";
import Login from "./screen/Login";
import ResetPassword from "./screen/ResetPassword";
import PasswordResetConfirm from "./screen/PasswordResetConfirm";

const Dashboard = React.lazy(() => import("./screen/Dashboard"));

function App() {
  const userManager = useUser();
  const location = useLocation();
  const [showHelp, setShowHelp] = useState(false);
  const helpPaths = ["/add-quiz", "/list-quiz"];

  if (!userManager.isLogin)
    return (
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/reset/password/" element={<ResetPassword />} />
        <Route
          exact
          path="/password/reset/:token"
          element={<PasswordResetConfirm />}
        />
      </Routes>
    );

  if (userManager.state.user) {
    return (
      // <QuizProvider>
      <Suspense fallback={<Loading />}>
        <DefaultLayout>
          {helpPaths.includes(location.pathname) && (
            <button
              className="show-help"
              onClick={() => setShowHelp(!showHelp)}
            >
              ?
            </button>
          )}

          <Routes>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route
              exact
              path="/add-quiz"
              element={<AddQuiz showHelp={showHelp} />}
            />

            <Route
              exact
              path="/add-quiz/:id/update"
              element={<UpdateQuiz showHelp={showHelp} />}
            />
            <Route exact path="/add-quiz/:id/delete" element={<QuizDelete />} />
            <Route
              exact
              path="/list-quiz"
              element={<ListQuiz showHelp={showHelp} />}
            />
            <Route exact path="/add-category" element={<AddCategory />} />
            
            <Route
              exact
              path="/add-category/:id/update"
              element={<UpdateCat />}
            />
            <Route
              exact
              path="/add-category/:id/delete"
              element={<CatDelete />}
            />
            <Route exact path="/add-question" element={<AddQuestion />} />
            <Route exact path="/list-questions" element={<ListQuestions />} />

            <Route exact path="/list-polls" element={<ListPolls />} />
            <Route exact path="/list-polls/:id/update" element={<UpdatePoll />} />
            <Route exact path="/add-polls" element={<AddPoll />} />
            <Route exact path="/list-polls/:id/delete" element={<DeletePoll />} />
            <Route exact path="/list-polls/:id/update/choices/:choice_id/update" element={<UpdateChoice />} />
            <Route exact path="/list-polls/:id/update/choices/:choice_id/delete" element={<DeletePollChoice />} />

            <Route
              exact
              path="/list-questions/:id/update"
              element={<UpdateQuestion />}
            />
            <Route
              exact
              path="/list-questions/:id/delete"
              element={<DeleteQues />}
            />
            <Route exact path="/quiz-report" element={<ListAttempts />} />

            <Route exact path="/viewers-data" element={<ListViewers />} />
            <Route exact path="/setting" element={<BussinessSetting />} />
            <Route path="*" element={<Navigate to={"/dashboard"} />} />
          </Routes>
        </DefaultLayout>
      </Suspense>
      // </QuizProvider>
    );
  }
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to={"/login"} />} />
      </Routes>
    </Suspense>
  );
}

export default App;
