import "./styles.scss";
import AppSidebar from "./AppSidebar";
import AppHeader from "./AppHeader";
import { CContainer } from "@coreui/react";
import { ErrorBoundary } from "react-error-boundary";

const ErrorHandler = ({ error }) => {
  return (
    <div role="alert">
      <p>An error occurred:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

const Layout = ({ children }) => {
  return (
    <div>
      {/* <QuizProvider> */}

      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <AppSidebar />
        <div className="body flex-grow-1 px-3">
          <CContainer lg>
            {/* <ErrorBoundary FallbackComponent={ErrorHandler}> */}
            {children}
            {/* </ErrorBoundary> */}
          </CContainer>
        </div>
        {/* <AppFooter /> */}
      </div>
      {/* </QuizProvider> */}
    </div>
  );
};

export default Layout;
