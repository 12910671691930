import { useState, useEffect } from "react";
import {
  cilArrowThickLeft,
  cilArrowThickRight,
  cilClipboard,
  cilCopy,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CFormSelect, CTableDataCell, CTableRow } from "@coreui/react";
import React from "react";
import useQuizes from "../../Hooks/useQuizes";
import useUser from "../../Hooks/user";
import { off, onValue, ref } from "firebase/database";
import { db } from "../../firebaseConfig";
import useDateTime  from "../../Hooks/useDateTime";
import { Link } from "react-router-dom";
import { statusOptions } from "../../constants";
const QuizRow = ({ quiz: initialQuiz }) => {
  const user = useUser();
  const clientId = user.state.user.client;
 const clientViewersAppUrl = user.state.user.client_details.settings.viewer_app_url;

  const { getDateTimeEasy } = useDateTime();
  const { UpdateQuiz } = useQuizes(clientId);
  const { ResetAttempts } = useQuizes();

  const [copied, setCopied] = useState(null);
  const [quiz, setQuiz] = useState(initialQuiz);
  // eslint-disable-next-line 
  const [quizAttempts, setquizAttempts] = useState(quiz?.attempts) 



  useEffect(() => {
    const quizRef = ref(db, initialQuiz.quiz_hash);
    onValue(quizRef, (snapshot) => {
      const data = snapshot.val();
      setQuiz(data);
    });
    return () => off(quizRef);
  }, [initialQuiz.quiz_hash]);

  return quiz ? (
    <CTableRow color="light">
      <CTableDataCell>{quiz.name}</CTableDataCell>
      {quiz.mode === "MANUAL" ? (
        <CTableDataCell>
          <CIcon
            className={`question-increment ${
              quiz.question_no <= 1 || quiz.status !== "STARTED"
                ? "disabled"
                : ""
            }`}
            icon={cilArrowThickLeft}
            onClick={() => {
              UpdateQuiz({
                ...initialQuiz,
                question_no: quiz.question_no - 1,
                status: quiz.status,
              });
            }}
          />{" "}
          {quiz.question_no}{" "}
          <CIcon
            className={`question-increment ${
              quiz.question_no >= (quiz.questions?.length | 0) ||
              quiz.status !== "STARTED"
                ? "disabled"
                : ""
            }`}
            icon={cilArrowThickRight}
            onClick={() => {
              UpdateQuiz({
                ...initialQuiz,
                question_no: quiz.question_no + 1,
                status: quiz.status,
              });
            }}
          />
        </CTableDataCell>
      ) : (
        <CTableDataCell>N/A</CTableDataCell>
      )}
      <CTableDataCell>{quiz.mode}</CTableDataCell>
      <CTableDataCell>
        <CFormSelect
          aria-label="Select"
          value={quiz.status}
          onChange={(e) => {
            UpdateQuiz({ ...initialQuiz, status: e.target.value });
          }}
          name="status"
        >
          <option disabled selected>
            Select
          </option>
          {statusOptions[quiz.mode].length > 0 ? (
            statusOptions[quiz.mode]
              .filter(
                (value, index) =>
                  index >=
                  statusOptions[quiz.mode].findIndex(
                    (i) => i.value === quiz.status
                  )
              )
              .map((option, index) => (
                <option key={index} value={option.value}>
                  {option.name}
                </option>
              ))
          ) : (
            <option value={null}>Select</option>
          )}
        </CFormSelect>
      </CTableDataCell>
      <CTableDataCell>{getDateTimeEasy(quiz.start_date_time)}</CTableDataCell>
      <CTableDataCell>{getDateTimeEasy(quiz.end_date_time)}</CTableDataCell>
      <CTableDataCell>
        {quiz.quiz_hash}{" "}
        {clientViewersAppUrl && <CIcon
          icon={copied && copied === quiz.quiz_hash ? cilClipboard : cilCopy}
          className="copy-icon"
          onClick={() => {
            navigator.clipboard.writeText(
              `${clientViewersAppUrl}/${quiz.quiz_hash}`
            );
            setCopied(quiz.quiz_hash);
            setTimeout(() => {
              setCopied(null);
            }, 1000);
          }}
        />}
      </CTableDataCell>
      <CTableDataCell>
        <Link to={"/add-quiz/" + quiz.id + "/update"}>
          <button type="button" className="btn btn-primary btn-sm">
            Edit
          </button>
        </Link>
      </CTableDataCell>
      <CTableDataCell>
        <Link to={"/add-quiz/" + quiz.id + "/delete"}>
          <button type="button" className="btn btn-danger btn-sm">
            delete
          </button>
        </Link>
      </CTableDataCell> 
      <CTableDataCell>
          <button 
            onClick={() => {
              ResetAttempts({
                quiz: quiz.id,
              })
            }}
          disabled= {quizAttempts.length  > 0 ? false : true}
          type="button" className={quizAttempts.length > 0 ? `btn btn-primary btn-sm` : `btn btn-secondary btn-sm` }>
            <i class="fas fa-refresh"style={{marginRight:"0.5rem"}} ></i>
             Attempts
          </button>
      </CTableDataCell>
    
    </CTableRow>
  ) : null;
};

export default QuizRow;
