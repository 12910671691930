import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../config";
import getHeaders from "./getHeaders";
import { useNavigate } from "react-router-dom";
import {jsonToFormData} from "./useBussinessSetting";


export default function usePolls() {
  const [state, setState] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const navigate = useNavigate();
  const headers = getHeaders();

  useEffect(() => {
    var cancelHandler = axios.CancelToken.source();
    axios
      .get(
        `${API_URL}/api/v1/poll-list/`,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((res) => {
        console.log("res poll", res)
        setState(res.data);
        setHasLoaded(true);
      })
      .catch((err) => {
        if (!axios.isAxiosError(err)) {
          console.log(err);
        }
      });
    return () => {
      cancelHandler.cancel();
    };
  }, []);

  const getPollId = async (id) => {
    const res = await axios.get(`${API_URL}/api/v1/poll-list/${id}/`, {
      headers,
    });
    return res.data;
  };

  // const searchCat = (searchString) => {
  //   if (searchString === "" && searchString === null) {
  //     getCat();
  //   } else {
  //     axios
  //       .get(
  //         `${API_URL}/api/v1/category/?search=${searchString.replaceAll(
  //           " ",
  //           "+"
  //         )}`,
  //         {
  //           headers,
  //         }
  //       )
  //       .then((res) => {
  //         setState(res.data);
  //         setHasLoaded(true);
  //       })
  //       .catch((err) => {
  //         if (!axios.isAxiosError(err)) {
  //           console.log(err);
  //         }
  //       });
  //   }
  // };

  const createPoll = (instance) => {
    var cancelHandler = axios.CancelToken.source();
    // const catData = {
    //   name: name,
    //   client: id,
    // };
    axios
      .post(
        `${API_URL}/api/v1/poll-list/`,
        jsonToFormData(instance),
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((res) => {
        navigate("/list-polls");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const getChoice = async (id) => {
    const res = await axios.get(`${API_URL}/api/v1/poll-choices/${id}/`, {
      headers,
    });
    return res.data;
  };
  

  const createPollchoice = (instance) => {
    console.log("instance choices", instance)
    var cancelHandler = axios.CancelToken.source();
    axios
      .post(
        `${API_URL}/api/v1/poll-choices/`,
        instance,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((res) => {
        // navigate("/list-polls");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const UpdatePollbyID = (instance) => {
    var cancelHandler = axios.CancelToken.source();
    // const CatData = {
    //  instance,
    // };
    axios
      .patch(
        `${API_URL}/api/v1/poll-list/${instance.id}/`,
        jsonToFormData(instance),
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((res) => {
        navigate("/list-polls");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deletePoll = (instance) => {
    var cancelHandler = axios.CancelToken.source();
    axios
      .delete(
        `${API_URL}/api/v1/poll-list/${instance}/`,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((response) => {
        navigate("/list-polls");
      })
      .catch((error) => {
        alert("There was an error!", error);
      });
  };
  const deletePollchoice = (instance, pollID) => {
    var cancelHandler = axios.CancelToken.source();
    axios
      .delete(
        `${API_URL}/api/v1/poll-choices/${instance}/`,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((response) => {
        navigate(`/list-polls/${pollID}/update`);
      })
      .catch((error) => {
        alert("There was an error!", error);
      });
  };

  const updatePollchoice = (instance) => {
    var cancelHandler = axios.CancelToken.source();
    const pollID = instance.question
    axios
      .patch(
        `${API_URL}/api/v1/poll-choices/${instance.id}/`,
       instance,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((res) => {
        navigate(`/list-polls/${pollID}/update`);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  return {
    state,
    getChoice,
    updatePollchoice,
    createPollchoice,
    deletePollchoice,
    deletePoll,
    createPoll,
    getPollId,
    UpdatePollbyID,
    hasLoaded,
  };
}
