import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../config";
import getHeaders from "./getHeaders";
import { useNavigate } from "react-router-dom";
import fileDownload from "js-file-download";

export default function useAttempts(id) {
  const [state, setState] = useState({ results: [] });
  const [hasLoaded, setHasLoaded] = useState(false);
  const navigate = useNavigate();

  const headers = getHeaders();

  // useEffect(() => {
  //   var cancelHandler = axios.CancelToken.source();
  //   axios
  //     .get(
  //       `${API_URL}/api/v1/attempts/`,
  //       {
  //         headers,
  //       },
  //       {
  //         cancelToken: cancelHandler.token,
  //       }
  //     )
  //     .then((res) => {
  //       setState(res.data);
  //       setHasLoaded(true);
  //     })
  //     .catch((err) => {
  //       if (!axios.isAxiosError(err)) {
  //         console.log(err);
  //       }
  //     });
  //   return () => {
  //     cancelHandler.cancel();
  //   };
  // }, []);

  const getAttempts = (pageNo = 1) => {
    axios
      .get(`${API_URL}/api/v1/attempts/?page=${pageNo}&client=${id}`, {
        headers,
      })
      .then((res) => {
        setState(res.data);
        setHasLoaded(true);
      })
      .catch((err) => {
        if (!axios.isAxiosError(err)) {
          console.log(err);
        }
      });
  };

  const getAttemptByQuizId = async (quizId) => {
    const res = await axios
      .get(`${API_URL}/api/v1/attempts/?quiz=${quizId}`, {
        headers,
      })
      .then((res) => {
        setState(res.data);
        setHasLoaded(true);
      })
      .catch((err) => {
        if (!axios.isAxiosError(err)) {
          console.log(err);
        }
      });
  };

  const searchQuestions = (searchString) => {
    if (searchString === "" && searchString === null) {
      getAttempts();
    } else {
      axios
        .get(
          `${API_URL}/api/v1/attempts/?search=${searchString.replaceAll(
            " ",
            "+"
          )}`,
          {
            headers,
          }
        )
        .then((res) => {
          setState(res.data);
          setHasLoaded(true);
        })
        .catch((err) => {
          if (!axios.isAxiosError(err)) {
            console.log(err);
          }
        });
    }
  };

  const exportViewersData = (searchString) => {
    return axios
      .get(
        `${API_URL}/api/v1/export-quiz-participants/${
          searchString !== null
            ? `?search=${searchString?.replaceAll(" ", "+")}`
            : ""
        }`,
        {
          headers,
          responseType: "blob",
        }
      )
      .then((res) => {
        return fileDownload(res.data, "report.csv");
      });
  };

  const Delete = (instance) => {
    var cancelHandler = axios.CancelToken.source();
    axios
      .delete(
        `${API_URL}/api/v1/attempts/${instance}/?client=${id}`,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((response) => {
        navigate("/quiz-report");
      })
      .catch((error) => {
        alert("There was an error!", error);
      });
  };

  const bulkDeleteAttempts = (ids) => {
    axios
      .post(
        `${API_URL}/api/v1/bulk-delete-attempts/`,
        {
          attempts: ids,
        },
        {
          headers,
        }
      )
      .then((res) => {
        setState({
          ...state,
          results: state.results.filter((attempt) => !ids.includes(attempt.id)),
        });
        navigate("/quiz-report");
      })
      .catch((error) => {
        alert("There was an error!", error);
      });
  };

  return {
    state,
    getAttempts,
    getAttemptByQuizId,
    bulkDeleteAttempts,
    searchQuestions,
    exportViewersData,
    Delete,
    hasLoaded,
  };
}
