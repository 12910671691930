import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../config";
import getHeaders from "./getHeaders";
import { useNavigate } from "react-router-dom";

export default function useQuizes(id) {
  const [state, setState] = useState({ results: [] });
  const [hasLoaded, setHasLoaded] = useState(false);
  const navigate = useNavigate();

  const headers = getHeaders();

  useEffect(() => {
    var cancelHandler = axios.CancelToken.source();
    axios
      .get(
        `${API_URL}/api/v1/portal-quiz/?client=${id}`,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((res) => {
        setState(res.data);
        setHasLoaded(true);
      })
      .catch((err) => {
        if (!axios.isAxiosError(err)) {
          console.log(err);
        }
      });

    return () => {
      cancelHandler.cancel();
    };
  }, []);

  const getQuizes = (pageNo = 1) => {
    axios
      .get(`${API_URL}/api/v1/portal-quiz/?page=${pageNo}&client=${id}`, {
        headers,
      })
      .then((res) => {
        setState(res.data);
        setHasLoaded(true);
      })
      .catch((err) => {
        if (!axios.isAxiosError(err)) {
          console.log(err);
        }
      });
  };

  const getQuizById = async (id) => {
    const res = await axios.get(`${API_URL}/api/v1/quiz/${id}/`, {
      headers,
    });
    return res.data;
  };

  const searchQuizes = (searchString) => {
    if (searchString === "" && searchString === null) {
      getQuizes();
    } else {
      axios
        .get(
          `${API_URL}/api/v1/quiz/?search=${searchString.replaceAll(" ", "+")}`,
          {
            headers,
          }
        )
        .then((res) => {
          setState(res.data);
          setHasLoaded(true);
        })
        .catch((err) => {
          if (!axios.isAxiosError(err)) {
            console.log(err);
          }
        });
    }
  };

  const QuizPost = (data) => {
    var cancelHandler = axios.CancelToken.source();
    const QuizData = {
      client: id,
      name: data.name,
      description: data.description,
      // instructions: data.instructions,
      mode: data.mode,
      status: data.status,
      active: data.active,
      qs_timer: data.qs_timer,
      timer_bw_qs: data.timer_bw_qs,
      before_qs_timer: data.before_qs_timer,
      time_based: data.time_based,
      show_options: data.show_options,
      show_count_down: data.show_count_down,
      question_no: data.question_no,
      questions: data.questions,
      max_attempt_count: data.max_attempt_count,
      join_code: data.join_code,
      quiz_hash: data.hash,
      start_date_time: data.start_date_time,
      end_date_time: data.end_date_time,
      image: data.image_video,
      sponsor_logo: data.sponsor_logo,
      sponsor_name: data.sponsor_name,
    };
    axios
      .post(
        `${API_URL}/api/v1/quiz/?client=${id}`,
        jsonToFormData(QuizData),
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((res) => {
        // console.log(res.data);
        navigate("/list-quiz");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateQuiz = (data) => {
    var cancelHandler = axios.CancelToken.source();
    const QuizData = {
      client: id,
      name: data.name,
      description: data.description,
      // instructions: data.instructions,
      mode: data.mode,
      status: data.status,
      active: data.active,
      qs_timer: data.qs_timer,
      timer_bw_qs: data.timer_bw_qs,
      before_qs_timer: data.before_qs_timer,
      time_based: data.time_based,
      show_options: data.show_options,
      show_count_down: data.show_count_down,
      question_no: data.question_no,
      questions: data.questions,
      max_attempt_count: data.max_attempt_count,
      join_code: data.join_code,
      quiz_hash: data.quiz_hash,
      start_date_time: data.start_date_time,
      end_date_time: data.end_date_time,
      image: data.image,
      sponsor_logo: data.sponsor_logo,
      sponsor_name: data.sponsor_name,
    };
    axios
      .patch(
        `${API_URL}/api/v1/quiz/${data.id}/?client=${id}`,
        jsonToFormData(QuizData),
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((res) => {
        setState({
          ...state,
          results: state.results.map((item) =>
            item.id === data.id ? res.data : item
          ),
        });
        navigate("/list-quiz");
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  const ResetAttempts = (QuizId) => {
    var cancelHandler = axios.CancelToken.source();
    axios
      .put(
        `${API_URL}/api/v1/quiz-reset-attempts/`,
        jsonToFormData(QuizId),
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((response) => {
        navigate("/list-quiz");
      })
      .catch((error) => {
        alert("There was an error!", error);
      });
  };


  const Delete = (instance) => {
    var cancelHandler = axios.CancelToken.source();
    axios
      .delete(
        `${API_URL}/api/v1/quiz/${instance}/?client=${id}`,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((response) => {
        navigate("/list-quiz");
      })
      .catch((error) => {
        alert("There was an error!", error);
      });
  };

  const jsonToFormData = (json) => {
    var form_data = new FormData();
    Object.keys(json).forEach((key) => {
      if (json[key] != null) {
        if (key === "questions") {
          json[key].forEach((question) => {
            form_data.append("questions", question);
          });
        } else {
          if (key === "image" || key === "sponsor_logo") {
            if (typeof json[key] != "string") {
              form_data.append(key, json[key]);
            }
          } else {
            form_data.append(key, json[key]);
          }
        }
      }
    });
    return form_data;
  };

  return {
    state,
    getQuizes,
    getQuizById,
    searchQuizes,
    QuizPost,
    Delete,
    UpdateQuiz,
    hasLoaded,
    ResetAttempts
  };
}
