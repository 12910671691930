import React from "react";
import Selection from "../../../Components/selection";
import useQuizes from "../../../Hooks/useQuizes";
import useUser from "../../../Hooks/user";

const QuizSelector = ({ value, onChange }) => {
  const user = useUser();
  const clientId = user.state.user.client;
  const { state } = useQuizes(clientId);
  return (
    <Selection
      label="Quiz"
      options={state.results.map((quiz) => ({
        name: quiz.name,
        value: quiz.id,
      }))}
      displayProperty="name"
      valueProperty="value"
      selectedOption={value}
      onSelect={onChange}
      name="status"
    />
  );
};

export default QuizSelector;
