import styled from "styled-components";
import { devices } from "../../../utils/styles/devices";

export const ListWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top:1rem;
  margin-bottom:1rem;

  @media ${devices.mobileS} {
    flex-direction: column;
    padding: 5px;
    gap: 2rem;


  }
  @media ${devices.laptop} {
    flex-direction: row;
    gap: 5rem;


  }

`;

export const DroppableStyle = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0.5px 2px rgba(16, 24, 40, 0.3);
  padding: 10px;
  text-align: center;
  width:100%;

  

  h2 {
    color: #667085;
    font-weight: bold;
    line-height: 18px;
    font-size: 15px;
    text-align: center;

  }
  .header{
    display: flex;
    flex-direction: row;
    align-items: center;

    

    .direction{
      font-size:1rem;
      display: flex;
      justify-content:space-around;
      align-items:center;
      gap:0.4rem;
      cursor:pointer;

      i{
        font-size:1rem;
        -webkit-text-stroke: 2px darkblue;


      }
    }
  }
`;


export const PaginationWrapper = styled.div`
  display: flex;
  margin-bottom:5rem;
  flex-direction: row;
  justify-content: space-between;
  

`;