import { CButton, CForm, CImage } from "@coreui/react";
import { useEffect, useState } from "react";
import useCategories from "../../Hooks/useCategories";
import useUser from "../../Hooks/user";
import useQuestions from "../../Hooks/useQuestions";
import "./styles.scss";
import Selection from "../../Components/selection";
import TextFeild from "../../Components/textFeild";
import UploadField from "../../Components/uploadField";

const AddQuestion = () => {
  const user = useUser();
  const clientId = user.state.user.client;
  const categories = useCategories(clientId);
  const { QuesPost } = useQuestions(clientId);
  // State Variables for the question creation
  const [selectedCategory, setSelectedCategory] = useState();
  const [sponsorImage, setSponsorImage] = useState(null);
  const [questionDescription, setQuestionDescription] = useState(null);
  const [questionHint, setQuestionHint] = useState(null);
  const [options, setOptions] = useState({
    a: "",
    b: "",
    c: "",
    d: "",
  });
  const [rawCorrectOptions, setRawCorrectOptions] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const QuestionData = {
      selectedCategory,
      questionDescription,
      questionHint,
      options,
      rawCorrectOptions,
      sponsorImage,
    };
    QuesPost(QuestionData);
  };

  useEffect(() => {
    document.title = "Add Question";
  }, []);

  return (
    <div className="add-question">
      <CForm onSubmit={handleSubmit}>
        <div className="heading">
          <h6>Create New Question</h6>
          <hr />
        </div>
        <div className="inline-fields">
          <Selection
            label="Select category"
            options={categories.state}
            displayProperty="name"
            valueProperty="id"
            selectedOption={selectedCategory}
            onSelect={(e) => setSelectedCategory(e.target.value)}
            req={true}
          />
        </div>
        <div className="inline-fields">
          <TextFeild
            label="Description"
            hint="Whats is the name of our country?"
            value={questionDescription}
            onChange={(e) => setQuestionDescription(e.target.value)}
            required
          />
          <TextFeild
            label="Hint"
            hint="starts from P"
            value={questionHint}
            onChange={(e) => setQuestionHint(e.target.value)}
          />
        </div>
        <div className="inline-fields">
          <UploadField
            label="Upload Sponsor Image"
            onChange={(e) => setSponsorImage(e.target.files[0])}
            imageOnly={true}
          />

          {sponsorImage && (
            <CImage
              rounded
              thumbnail
              src={URL.createObjectURL(sponsorImage)}
              width={200}
              height={200}
            />
          )}
        </div>
        <div className="quiz-details">
          <div className="Options">
            {Object.keys(options).map((key) => (
              <TextFeild
                key={key}
                label={`Option ${key.toUpperCase()}`}
                value={options[key]}
                onChange={(e) =>
                  setOptions({ ...options, [key]: e.target.value })
                }
                required={true}
              />
            ))}
          </div>
          <div className="correct-option">
            <TextFeild
              label="Correct Option"
              hint="A"
              value={rawCorrectOptions}
              onChange={(e) =>
                setRawCorrectOptions(e.target.value.toUpperCase())
              }
              required={true}
            />
          </div>
        </div>
        <div className="d-grid col-2">
          <CButton type="submit">Save</CButton>
        </div>
      </CForm>
    </div>
  );
};

export default AddQuestion;
