import { useState } from "react";
import * as S from "./styles";


const Card = ({ title, listName, item, selectedStore, allDataStore,ischeck }) => {  

  if(listName === 'selectedQuestions')
  return (
    <S.Card>
      <div className="card-header">
      <input type="checkbox" id={item?.created_at} name={item?.created_at} value={item?.id}
      onChange={() => selectedStore(item)}
      />
        <label for={item?.created_at}>{title}</label>
      </div>
    </S.Card>
  );
  else{
    return (
      <S.Card>
        <div className="card-header">
        <input type="checkbox" id={item?.created_at} name={item?.created_at} value={item?.id}
        onChange={() => allDataStore(item)}
        />
          <label for={item?.created_at} className="text-dark">{title}</label>
        </div>
       
        
      </S.Card>
    );
  }
};

export default Card;
