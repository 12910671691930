import axios from "axios";
import {API_URL}  from "../config";

export default function resetPasswordConfirm(values) {
  const data = {
    new_password: values.pass,
    new_password1: values.passConfirm,
    token: values.token
  };
  return axios
    .post(
      `${API_URL}/password/reset/`,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}
