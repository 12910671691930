import {
  CButton,
  CForm,
  CFormCheck,
  CFormLabel,
  CImage,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import useCategories from "../../Hooks/useCategories";
import useQuestions from "../../Hooks/useQuestions";
import useUser from "../../Hooks/user";
import useQuizes from "../../Hooks/useQuizes";

import "./styles.scss";
import TextFeild from "../../Components/textFeild";
import Selection from "../../Components/selection";
// import TextAreaField from "../../Components/textAreaField";
import UploadField from "../../Components/uploadField";
import ReactPaginate from "react-paginate";
import { ITEMS_PER_PAEG } from "../../config";
import { quizModes, statusOptions } from "../../constants";
import HowToCreateQuiz from "./howTo";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Card from "./Card";
import * as S from "./QuestionMultiDrag/styles";

const AddQuiz = ({ showHelp }) => {
  const user = useUser();
  const clientId = user.state.user.client;
  const clientDetails = user.state.user.client_details;
  const categories = useCategories(clientId);
  const { state, hasLoaded, getQuestions } = useQuestions(clientId);
  const { QuizPost } = useQuizes(clientId);

  // State Variables for the quiz creation
  const [image, setImage] = useState(null);
  const [sponsorImage, setSponsorImage] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [allQuestions, setAllQuestions] = useState([]);
  const [selectedCategoryIds, setSelectedCategories] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allCheckedQues, setallCheckedQues] = useState([]);
  const [selectedCheckedQues, setselectedCheckedQues] = useState([]);
  const [selectedQues, setselectedQues] = useState([]);
  const [allQues, setallQues] = useState([]);
  const [authSelectedOption, setSelectedOption] = useState("FORM_BASED");

  const [QuizData, setQuizData] = useState({
    name: "",
    mode: null,
    status: "ANNOUNCED",
    active: true,
    timer: "",
    hash: "",
    question_no: "1",
    qs_timer: "",
    timer_bw_qs: "",
    before_qs_timer: "",
    time_based: false,
    show_count_down: false,
    show_options: false,
    join_code: "",
    max_attempt_count: "1",
    image: "",
    sponsor_name: "",
    sponsor_logo: "",
    // instructions: "",
    description: "TODO: remove this from backend",
    start_date_time: null,
    end_date_time: null,
    questions: [],
    auth_used: authSelectedOption
  });

  useEffect(() => {
    document.title = "Add Quiz";

    if (hasLoaded) {
      // add a checked property to each question
      const questionsWithChecked = state.results.map((question) => {
        return { ...question, checked: false };
      });
      setallQues(questionsWithChecked);
      setAllQuestions(questionsWithChecked);
      setFilteredQuestions(questionsWithChecked);
    }
    setPageCount(Math.ceil(state.count / ITEMS_PER_PAEG));
  }, [state, hasLoaded]);

  // whenever the categories change, update the filtered questions
  useEffect(() => {
    // console.log("filtering questions");
    if (selectedCategoryIds.length > 0) {
      const filterQuestions = () => {
        return allQuestions.filter((question) => {
          return selectedCategoryIds.includes(question.category?.toString());
        });
      };
      setallQues(filterQuestions());
      setFilteredQuestions(filterQuestions());
    } else {
      setFilteredQuestions(allQuestions);
      setallQues(allQuestions);
    }
  }, [selectedCategoryIds]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setQuizData((QuizData) => ({
      ...QuizData,
      [name]: value,
    }));
  };

  const handlePageClick = (event) => {
    getQuestions(event.selected + 1);
  };

  const handleCategorySelect = (e) => {
    if (e.target.checked) {
      setSelectedCategories((selectedCategoryIds) => [
        ...selectedCategoryIds,
        e.target.value,
      ]);
    } else {
      const newSelectedCategoryIds = [...selectedCategoryIds];
      if (newSelectedCategoryIds.indexOf(e.target.value) > -1) {
        newSelectedCategoryIds.splice(
          newSelectedCategoryIds.indexOf(e.target.value),
          1
        );
      }
      setSelectedCategories(newSelectedCategoryIds);
    }
  };

  const handleQuestionSelect = (e, questionIndexInFilteredList) => {
    if (e.target.checked) {
      // console.log("checked", e.target.value);
      // console.log("index", questionIndexInFilteredList);
      const newFilteredQuestions = [...filteredQuestions];
      newFilteredQuestions[questionIndexInFilteredList].checked = true;
      setFilteredQuestions(newFilteredQuestions);
    } else {
      // console.log("unchecked", e.target.value);
      const newFilteredQuestions = [...filteredQuestions];
      newFilteredQuestions[questionIndexInFilteredList].checked = false;
      setFilteredQuestions(newFilteredQuestions);
    }
  };

  function formetDateTime(date, time) {
    if (date) {
      var formated = new Date(date);
      if (time) {
        var time = time.match(/(\d+)(?::(\d\d))?\s*(p?)/);
        formated.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
        formated.setMinutes(parseInt(time[2]) || 0);
      }
    }
    return formated.toISOString();
  }

  const disablePastDate = (dateObj) => {
    const today = dateObj ? dateObj : new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // get the selected questions
    const listOfSelecteedQuestionIds = filteredQuestions
      .filter((question) => question.checked)
      .map((question) => question.id);

    const listOfselectedQuesIds = selectedQues.map((question) => question.id);

    //add the selected questions to the quizPayload
    const quizDataPayload = {
      ...QuizData,
      questions: listOfselectedQuesIds,
    };

    console.log("data", quizDataPayload);
    QuizPost(quizDataPayload);
  };

  const handleGetListWithSetter = (listName) => {
    switch (listName) {
      case "selectedQuestions":
        return { list: selectedQues, setList: setselectedQues };
      case "allQuestions":
        return { list: allQues, setList: setallQues };
      default:
        return { list: selectedQues, setList: setselectedQues };
    }
  };

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      const listDetails = handleGetListWithSetter(source.droppableId);
      const orderResult = handleReOrder(
        listDetails.list,
        source.index,
        destination.index
      );
      listDetails.setList(orderResult);
    } else {
      console.log("handleMove", source, destination);
      handleMove(
        source.droppableId,
        destination.droppableId,
        source,
        destination
      );
    }
  };

  const handleMove = (
    sourceId,
    destId,
    droppableSource,
    droppableDestination
  ) => {
    const sourceListDetails = handleGetListWithSetter(sourceId);
    const destListDetails = handleGetListWithSetter(destId);
    const sourceClone = Array.from(sourceListDetails.list);
    const destClone = Array.from(destListDetails.list);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    console.log(sourceListDetails.list, destListDetails.list);

    sourceListDetails.setList(sourceClone);
    destListDetails.setList(destClone);
  };

  const handleReOrder = (list, sourceIndex, destinationIndex) => {
    const listClone = Array.from(list);
    const [removed] = listClone.splice(sourceIndex, 1);
    listClone.splice(destinationIndex, 0, removed);

    return listClone;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: "5px 0",
    background: "transparent",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: "transparent",
    width: "320px",
  });

  const moveToMainData = () => {
    let yFilter = selectedCheckedQues.map((itemY) => {
      return itemY.id;
    });
    let filteredX = selectedQues.filter((itemX) => !yFilter.includes(itemX.id));
    setselectedQues(filteredX);

    var arr = [];
    selectedCheckedQues.forEach(function (data) {
      var findData = allQues.find((x) => x.id === data.id);
      if (findData === undefined) {
        arr = allQues.concat(selectedCheckedQues);
        setallQues(arr);
      }
    });
    setselectedCheckedQues([]);
  };

  const moveToSelectedData = () => {
    let yFilter = allCheckedQues.map((itemY) => {
      return itemY.id;
    });
    let filteredX = allQues.filter((itemX) => !yFilter.includes(itemX.id));
    setallQues(filteredX);

    var arr = [];
    allCheckedQues.forEach(function (data) {
      var findData = selectedQues.find((x) => x.id === data.id);
      if (findData === undefined) {
        arr = selectedQues.concat(allCheckedQues);
        setselectedQues(arr);
      }
    });
    setallCheckedQues([]);
  };

  const handleCheckedSelectedStore = (item) => {
    // var idObj = selectedQues.find(x => x.id === item.id);
    var selectedObj = selectedCheckedQues.find((x) => x.id === item.id);
    var arr = [];

    if (selectedObj === undefined) {
      arr = selectedCheckedQues.concat(item);
      setselectedCheckedQues(arr);
    } else {
      var filterObjs = selectedCheckedQues.filter((x) => x.id !== item.id);
      setselectedCheckedQues(filterObjs);
    }
  };

  const handleCheckedAllDataStore = (item) => {
    var selectedObj = allCheckedQues.find((x) => x.id === item.id);
    var arr = [];

    if (selectedObj === undefined) {
      arr = allCheckedQues.concat(item);
      setallCheckedQues(arr);
    } else {
      var filterObjs = allCheckedQues.filter((x) => x.id !== item.id);
      setallCheckedQues(filterObjs);
    }
  };

  const createDraggable = (list, listName) => {
    return list.map((item, index) => {
      return (
        <Draggable
          key={item.created_at}
          draggableId={item.created_at}
          index={index}
        >
          {(provided, snapshot) => (
            <div
              {...provided.draggableProps}
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
            >
              <Card
                item={item}
                allDataStore={handleCheckedAllDataStore}
                selectedStore={handleCheckedSelectedStore}
                title={item.description}
                position={item.position}
                priority="high"
                listName={listName}
              />
            </div>
          )}
        </Draggable>
      );
    });
  };

  const createDroppable = (listName, list) => {
    return (
      <S.DroppableStyle>
        {listName == "selectedQuestions" ? (
          <div className="header">
            <h2 className="text-secondary">
              {createHumanReadableTitle(listName)}{" "}
            </h2>
            {selectedCheckedQues.length !== 0 && (
              <div className="direction" onClick={moveToMainData}>
                <small className="badge bg-primary">
                  {" "}
                  {selectedCheckedQues.length}
                </small>
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            )}
          </div>
        ) : (
          <div className="header">
            {allCheckedQues.length !== 0 && (
              <div className="direction" onClick={moveToSelectedData}>
                <i className="fa-solid fa-arrow-left"></i>
                <small className="badge bg-primary">
                  {" "}
                  {allCheckedQues.length}
                </small>
              </div>
            )}

            <h2 className="text-secondary">
              {createHumanReadableTitle(listName)}{" "}
            </h2>
          </div>
        )}

        <Droppable droppableId={listName}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={getListStyle(snapshot.isDragging)}
            >
              {createDraggable(list, listName)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </S.DroppableStyle>
    );
  };

  const createHumanReadableTitle = (listName) => {
    const result = listName.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  console.log("clientDetails", clientDetails.settings.social_auth_feature);

  return (
    <div className="row">
      <div className="col">
        <div className="add-quiz">
          <CForm onSubmit={handleSubmit}>
            <div className="heading">
              <h6>Create New Quiz</h6>
              <hr />
            </div>
            <div className="row">
              <TextFeild
                label="Name"
                hint="Enter a quiz name"
                value={QuizData.name}
                onChange={handleChange}
                name="name"
                required={true}
              />
              <Selection
                label="Select Mode"
                options={quizModes}
                displayProperty="name"
                valueProperty="value"
                selectedOption={QuizData.mode}
                onSelect={handleChange}
                name="mode"
                req={true}
              />
              <Selection
                label="Status"
                options={statusOptions[QuizData.mode || "AUTO"]}
                displayProperty="name"
                valueProperty="value"
                selectedOption={QuizData.status}
                onSelect={handleChange}
                name="status"
              />
            </div>
            {/* <UploadField
              label="Upload Image"
              imageOnly={true}
              onChange={(e) => {
                setQuizData({
                  ...QuizData,
                  image: e.target.files[0],
                });
                setImage(URL.createObjectURL(e.target.files[0]));
              }}
            />

            {(image || QuizData.image_video) && (
              <CImage
                rounded
                thumbnail
                src={image || QuizData.image}
                width={200}
                height={200}
              />
            )} */}

            <div className="row">
              <TextFeild
                label="Question Timer"
                hint="60"
                value={QuizData.qs_timer}
                onChange={handleChange}
                name="qs_timer"
                type="number"
                minimumValue={0}
                required={true}
              />

              {QuizData.mode === "AUTO" && (
                <TextFeild
                  label="Waiting Time b/w questions"
                  hint="in seconds"
                  value={QuizData.timer_bw_qs}
                  onChange={handleChange}
                  name="timer_bw_qs"
                  type="number"
                  minimumValue={0}
                  required={true}
                />
              )}

              <TextFeild
                label="Question Alert Timer"
                hint="in seconds"
                value={QuizData.before_qs_timer}
                onChange={handleChange}
                name="before_qs_timer"
                type="number"
                minimumValue={0}
                required={true}
              />
              {QuizData.mode === "MANUAL" && (
                <TextFeild
                  label="Question No"
                  hint="1"
                  initialValue={1}
                  value={QuizData.question_no}
                  onChange={handleChange}
                  name="question_no"
                  type="number"
                  minimumValue={1}
                />
              )}
            </div>

            <div className="mb-3">
              {QuizData.mode === "AUTO" && (
                <CFormCheck
                  inline
                  id="inlineCheckbox1"
                  value={QuizData.time_based}
                  onChange={() => {
                    setQuizData({
                      ...QuizData,
                      time_based: !QuizData.time_based,
                    });
                  }}
                  label="Timer Based"
                />
              )}

              <CFormCheck
                inline
                id="inlineCheckbox2"
                value={QuizData.show_count_down}
                onChange={() => {
                  setQuizData({
                    ...QuizData,
                    show_count_down: !QuizData.show_count_down,
                  });
                }}
                label="Show Count Down"
              />
            </div>

            <div className="row">
              <TextFeild
                label="Join Code"
                hint="1234"
                value={QuizData.join_code}
                onChange={(e) =>
                  e.target.value.length <= 4 ? handleChange(e) : null
                }
                name="join_code"
                type="number"
                required={true}
              />

              <TextFeild
                label="Max Attempt Allowed"
                hint="1"
                value={QuizData.max_attempt_count}
                onChange={handleChange}
                name="max_attempt_count"
                type="number"
                required={true}
              />
            </div>

            <div className="row">
              <TextFeild
                label="Quiz Start Date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setQuizData({
                    ...QuizData,
                    start_date_time: formetDateTime(e.target.value, startTime),
                  });
                }}
                type="date"
                minimumValue={disablePastDate()}
                required={true}
              />

              <TextFeild
                label="Quiz Start Time"
                value={startTime}
                onChange={(e) => {
                  setStartTime(e.target.value);
                  setQuizData({
                    ...QuizData,
                    start_date_time: formetDateTime(startDate, e.target.value),
                  });
                }}
                type="time"
                required={true}
              />

              <TextFeild
                label="Quiz End Date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setQuizData({
                    ...QuizData,
                    end_date_time: formetDateTime(e.target.value, endTime),
                  });
                }}
                type="date"
                minimumValue={disablePastDate(
                  new Date(QuizData.start_date_time)
                )}
                required={true}
              />

              <TextFeild
                label="Quiz End Time"
                value={endTime}
                onChange={(e) => {
                setEndTime(e.target.value);
                  setQuizData({
                    ...QuizData,
                    end_date_time: formetDateTime(endDate, e.target.value),
                  });
                }}
                type="time"
                required={true}
              />
            </div>

            <div className="row">
              <UploadField
                label="Upload Sponsor Image"
                imageOnly={true}
                onChange={(e) => {
                  setQuizData({
                    ...QuizData,
                    sponsor_logo: e.target.files[0],
                  });
                  setSponsorImage(URL.createObjectURL(e.target.files[0]));
                }}
              />

              <TextFeild
                label="Sponsor Name"
                hint="Name"
                value={QuizData.sponsor_name}
                onChange={handleChange}
                name="sponsor_name"
              />
            </div>

            {(sponsorImage || QuizData.sponsor_logo) && (
              <CImage
                rounded
                thumbnail
                src={sponsorImage || QuizData.sponsor_logo}
                width={200}
                height={200}
              />
            )}

            <div className="quiz-details">
              {/* <div className="instructions">
                <TextAreaField
                  label="Instructions"
                  name="instructions"
                  hint="Instruction for Quiz"
                  value={QuizData.instructions}
                  onChange={handleChange}
                  numberOfRows={3}
                  required={true}
                />
              </div> */}
              {/* <div className="descriptions">
                <TextAreaField
                  label="Description"
                  name="description"
                  hint="Description for Quiz"
                  value={QuizData.description}
                  onChange={handleChange}
                  numberOfRows={3}
                  required={true}
                />
              </div> */}
            </div>

            {categories.hasLoaded && (
              <div className="quiz-question-categories">
                <CForm>
                  <div className="mb-3">
                    <CFormLabel>Filter By Categories</CFormLabel>
                    <div>
                      {categories.state.map((category, index) => (
                        <CFormCheck
                          key={index}
                          inline
                          id={`categoryCheckbox${index}`}
                          value={category.id}
                          label={category.name}
                          onChange={handleCategorySelect}
                        />
                      ))}
                    </div>
                  </div>
                </CForm>

                <CForm>
                  <div className="mb-3">
                    <CFormLabel>
                      Choose authentication method for viewers{" "}
                    </CFormLabel>
                    {clientDetails && clientDetails.settings.social_auth_feature === true ? (
                      <div className="d-flex gap-2">
                        <CFormCheck
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value="FORM_BASED"
                          label="Form Based Authentication"
                          checked={authSelectedOption === "FORM_BASED"}
                          onChange={() => setSelectedOption("FORM_BASED")}
                        />
                        <CFormCheck
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          value="SOCIAL_BASED"
                          checked={authSelectedOption === "SOCIAL_BASED"}
                          onChange={() => setSelectedOption("SOCIAL_BASED")}
                          label="Social Based Authentication"
                        />
                        <CFormCheck
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          value="BOTH"
                          label="Use Both"
                          checked={authSelectedOption === "BOTH"}
                          onChange={() => setSelectedOption("BOTH")}
                        />
                      </div>
                    ) : (
                      <div className="d-flex gap-2">
                        <div className="d-flex gap-2">
                          <CFormCheck
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            value="FORM_BASED"
                            label="Form Based Authentication"
                            checked={authSelectedOption === "FORM_BASED"}
                          />
                          <CFormCheck
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            value="SOCIAL_BASED"
                            disabled={true}
                            label="Social Based Authentication"
                          />
                          <CFormCheck
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            value="BOTH"
                            label="Use Both"
                            disabled={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </CForm>
              </div>
            )}
            {/* 
            {hasLoaded && (
              <div className="quiz-questions">
                <CTable>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col"></CTableHeaderCell>
                      <CTableHeaderCell scope="col">Question</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Category</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {filteredQuestions.map((question, index) => (
                      <CTableRow color="light" key={index}>
                        <CTableDataCell width="5%">
                          <CFormCheck
                            id={`questionCheckbox${question.id}`}
                            name="questions"
                            value={question.id}
                            checked={question.checked}
                            onChange={(e) => handleQuestionSelect(e, index)}
                          />
                        </CTableDataCell>
                        <CTableDataCell scope="row" width="65%">
                          {question.description}
                        </CTableDataCell>
                        <CTableDataCell width="30%">
                          {question.category_detail[0]
                            ? question.category_detail[0].name
                            : "-"}
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>

                <div className="d-flex justify-content-between align-items-center">
                  <ReactPaginate
                    containerClassName="pagination mt-2"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    nextPageRel={state.next}
                    prevPageRel={state.previous}
                    renderOnZeroPageCount={null}
                  />
                  <p style={{ color: "#7a7a7a" }}>
                    {state.count} {state.count > 1 ? "questions" : "question"}
                  </p>
                </div>
              </div>
            )} */}
            {hasLoaded && (
              <S.ListWrapper>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  {createDroppable("selectedQuestions", selectedQues)}
                  {createDroppable("allQuestions", allQues)}
                </DragDropContext>
              </S.ListWrapper>
            )}
            <S.PaginationWrapper>
              <div>
                <CButton type="submit" className="mr-btn btn-md">
                  Save
                </CButton>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <ReactPaginate
                  containerClassName="pagination mt-2"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  breakLabel="..."
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  nextPageRel={state.next}
                  prevPageRel={state.previous}
                  renderOnZeroPageCount={null}
                />
                {/* <small style={{ color: "#7a7a7a" }}>
                    {questions.state.count}{" "}
                    {questions.state.count > 1 ? "questions" : "question"}
                  </small> */}
              </div>
            </S.PaginationWrapper>
          </CForm>
        </div>
      </div>
      {showHelp && (
        <div className="col">
          <HowToCreateQuiz />
        </div>
      )}
    </div>
  );
};

export default AddQuiz;
