import { useLayoutEffect } from "react";
import usePolls from "../../Hooks/usePolls";
import { Link, useParams } from "react-router-dom";
import useUser from "../../Hooks/user";

const DeletePoll = () => {
  const user = useUser();
  const clientId = user.state.user.client;
  const { deletePoll } = usePolls();
  const { id } = useParams();

  const DeleteInstance = (e) => {
    deletePoll(e);
  };

  useLayoutEffect(() => {
    document.title = "Delete";
  }, []);

  return (
    <div className="list-attempts">
      <div className="heading">
        <h6>Poll Delete</h6>
        <hr />
      </div>
      <div className="dataDelete">
        <h6>Are you sure you want to delete this Poll Instance?</h6>
        <Link to="/list-polls">
          <button type="button" class="btn btn-primary btn-sm">
            Cancel
          </button>
        </Link>
        &nbsp;&nbsp;
        <button
          type="button"
          class="btn btn-danger btn-sm"
          value={id}
          onClick={(e) => DeleteInstance(e.target.value)}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeletePoll;
