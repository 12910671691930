import { useLayoutEffect, useEffect, useState } from "react";
import useCategories from "../../Hooks/useCategories";
import { Link, useParams } from "react-router-dom";
import useUser from "../../Hooks/user";

const CatDelete = () => {
  const user = useUser();
  const clientId = user.state.user.client;
  const { Delete } = useCategories(clientId);
  const { id } = useParams();

  const DeleteInstance = (e) => {
    Delete(e);
  };

  useLayoutEffect(() => {
    document.title = "Delete";
  }, []);

  return (
    <div className="list-attempts">
      <div className="heading">
        <h6>Category Delete</h6>
        <hr />
      </div>
      <div className="dataDelete">
        <h6>Are you sure you want to delete this Category Instance?</h6>
        <Link to="/add-category">
          <button type="button" class="btn btn-primary btn-sm">
            Cancel
          </button>
        </Link>
        &nbsp;&nbsp;
        <button
          type="button"
          class="btn btn-danger btn-sm"
          value={id}
          onClick={(e) => DeleteInstance(e.target.value)}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default CatDelete;
