const statusOptions = {
  AUTO: [
    { name: "Announced", value: "ANNOUNCED" },
    { name: "Started", value: "STARTED" },
    { name: "Result Awaited", value: "RESULT_AWAITED" },
    { name: "Result Announced", value: "RESULT_ANNOUNCED" },
    { name: "Completed", value: "COMPLETED" },
  ],
  MANUAL: [
    { name: "Announced", value: "ANNOUNCED" },
    { name: "Activated", value: "ACTIVATED" },
    { name: "Started", value: "STARTED" },
    { name: "Result Awaited", value: "RESULT_AWAITED" },
    { name: "Result Announced", value: "RESULT_ANNOUNCED" },
    { name: "Completed", value: "COMPLETED" },
  ],
};

const quizModes = [
  // { name: "Auto", value: "AUTO" },
  { name: "Manual", value: "MANUAL" },
];

export { statusOptions, quizModes };
