import { useLayoutEffect, useEffect, useState } from "react";
import usePolls from "../../Hooks/usePolls";
import { Link, useParams } from "react-router-dom";
import useUser from "../../Hooks/user";

import {
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import UploadField from "../../Components/uploadField";


const UpdatePoll = () => {
  const user = useUser();
  const { id } = useParams();
  const clientId = user.state.user.client;
  const {getPollId, UpdatePollbyID, createPollchoice, deletePollchoice} = usePolls();
  // State Variables for the question creation
  const [poll, setPoll] = useState({});
  console.log("polls detail", poll)

  const [newChoice, setNewChoice] = useState({
    name: "",
    votes:0,
    question:id
    // Add other properties as needed
  });

  // Handle changes in choice input fields
  const handleChoiceChange = (e) => {
    const { name, value } = e.target;
    setNewChoice({
      ...newChoice,
      [name]: value,
    });
  };

  // Handle submission of new choice
  const handleAddChoice = (e) => {
    e.preventDefault();

    // Update poll state with the new choice
    setPoll({
      ...poll,
      options: [...poll.options, newChoice],
    });

    createPollchoice(newChoice);

    // Clear the new choice state
    setNewChoice({
      name: "",
      // Add other properties as needed
    });
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    setPoll({
      ...poll,
      [name]: inputValue,
    });
  };

  const handleDeleteChoice = (index, id) => {
    const updatedOptions = [...poll.options];
    updatedOptions.splice(index, 1);
    setPoll({
      ...poll,
      options: updatedOptions,
    });

    deletePollchoice(id);
    
  };

  const UpdateInstance = (e) => {
    e.preventDefault();
    console.log("updating poll", poll)
    UpdatePollbyID(poll);
  };

  useLayoutEffect(() => {
    document.title = "Update";
  }, []);

  useEffect(() => {
    getPollId(id).then((cat) => setPoll(cat));
  }, []);

  return (
    <div className="contianer list-attempts">
      <div className="container heading">
        <h6>Edit Poll</h6>
        <hr />
      </div>
      <div className="dataUpdate container">
        <form onSubmit={UpdateInstance}>
          <label className="mb-1">Poll Name</label>
          <input
            type="text"
            className="form-control mb-4"
            name="name"
            value={poll.name}
            onChange={handleChange}
            required
          />
           <label className="mb-1">Poll Join code</label>
          <input
            type="text"
            className="form-control mb-4"
            name="join_code"
            value={poll.join_code}
            onChange={handleChange}
            required
          />
           <label className="mb-1">Poll Hash code</label>
          <input
            type="text"
            className="form-control mb-4"
            name="poll_hash"
            value={poll.poll_hash}
            onChange={handleChange}
            required
          />
        <img src={poll?.qr_image_url} alt="qr"
        width="150"height="150"
        />
        <UploadField
            label="Poll QR Image "
            imageOnly={true}
            name="qr_image"
            onChange={(e) => {
              setPoll({
                ...poll,
                qr_image: e.target.files[0],
              });
            }}
          />

        <div className="d-flex gap-3 mb-3">
          <div className="d-flex gap-1">
          <input
              type="checkbox"
              name="show_options"
              checked={poll.show_options}
              onChange={handleChange}

            />
        <label className="mb-1">Show result options</label>
           
          </div>

         <div className="d-flex gap-1">
         <input
              type="checkbox"
              name="show_total_votes"
              checked={poll.show_total_votes}
              onChange={handleChange}
       
            />
         <label className="mb-1">Show total votes count</label>
       
         </div>
        </div>
         <div className="d-flex justify-content-end">
          
         <Link to="/list-polls">
            <button type="button" className="btn btn-danger btn-sm">
              Cancel
            </button>
          </Link>
          &nbsp; &nbsp;
          <button type="submit" className="btn btn-primary btn-sm" value={id}>
            Update Poll
          </button>
         </div>
        </form>



        <h6 className="mt-5">Poll Choices for {poll.name}</h6>
        <form onSubmit={handleAddChoice} className="d-flex gap-2 ">
         <div className="d-flex gap-2">
          <input
            type="text"
            name="name"
            className="form-control col"
            placeholder="Poll choice name"
            value={newChoice.name}
            onChange={handleChoiceChange}
            required
          />
         </div>
          {/* Add other input fields as needed */}

          <button type="submit" className="btn btn-primary btn-sm">
            Add Choice
          </button>
        </form>
        <div className="quiz-questions">
            <CTable responsive className="text-start">
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Current Votes</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Actions</CTableHeaderCell>

                </CTableRow>
              </CTableHead>
              <CTableBody>
                {poll && poll?.options?.map((choice, index) => {
                      return <CTableRow key={index}>
                       <CTableDataCell>{choice.name}</CTableDataCell>
                       <CTableDataCell>{choice.votes}</CTableDataCell>     
                       <CTableDataCell className="d-flex gap-2">
                       <Link to={"choices/" + choice.id + "/update"}>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                            >
                              Edit
                            </button>
                          </Link>
                          <Link to={"choices/" + choice.id + "/delete"}>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                            >
                              Delete
                            </button>
                          </Link>
                        </CTableDataCell>  
                              
                     </CTableRow>
                })}
              </CTableBody>
            </CTable>
          
          </div>

      </div>
    </div>
  );
};

export default UpdatePoll;
