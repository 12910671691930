import { CSpinner } from "@coreui/react";
import useWindowDimensions from "./Dimensions";

const Loading = () => {
  const { height } = useWindowDimensions();
  return (
    <div className="loading-spinner" style={{ height: height }}>
      <CSpinner size="sm" variant="grow" />
    </div>
  );
};

export default Loading;
