import React from "react";

const HowToListQuiz = () => {
  return (
    <div className="how-to">
      <div className="heading">
        <h6>Help</h6>
        <hr />
      </div>
      <ul>
        <li>
          <b>Name:</b> It is te name of te quiz.
        </li>
        <li>
          <b>Questions:</b> This column is for controlling the current question
          number of te quiz. It is only applicable when:
          <ul>
            <li>
              Mode of quiz is <b>MANUAL</b>.
            </li>
            <li>
              Quiz status soul be set to <b>Started</b>.
            </li>
          </ul>
        </li>
        <li>
          <b>Mode:</b> This indicates the current beavior of te quiz.
          <br />
          There are two modes available:
          <ul>
            <li>
              <b>MANUAL:</b> Admin decides when to change the status of the
              quiz.
            </li>
            <li>
              <b>AUTO:</b> System automatically changes the status a/c to quiz
              setting.
            </li>
          </ul>
        </li>
        <li>
          <b>Quiz Hash:</b> This is unique hash of te quiz.
        </li>
      </ul>
    </div>
  );
};

export default HowToListQuiz;
