import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../config";
import getHeaders from "./getHeaders";
import { useNavigate } from "react-router-dom";

export default function useQuestions(id) {
  const [state, setState] = useState({ results: [] });
  const [hasLoaded, setHasLoaded] = useState(false);
  const navigate = useNavigate();
  const headers = getHeaders();

  useEffect(() => {
    var cancelHandler = axios.CancelToken.source();
    axios
      .get(
        `${API_URL}/api/v1/questions/?client=${id}`,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((res) => {
        setState(res.data);
        setHasLoaded(true);
      })
      .catch((err) => {
        if (!axios.isAxiosError(err)) {
          console.log(err);
        }
      });

    return () => {
      cancelHandler.cancel();
    };
  }, []);

  const getQuestions = (pageNo = 1) => {
    axios
      .get(`${API_URL}/api/v1/questions/?page=${pageNo}&client=${id}`, {
        headers,
      })
      .then((res) => {
        setState(res.data);
        setHasLoaded(true);
      })
      .catch((err) => {
        if (!axios.isAxiosError(err)) {
          console.log(err);
        }
      });
  };

  const getQuestionById = async (id) => {
    const res = await axios.get(`${API_URL}/api/v1/questions/${id}/`, {
      headers,
    });
    console.log(res.data.options)
    return { ...res.data, options: decodeOptions(res.data.options) };
  };

  const searchQuestions = (searchString) => {
    if (searchString === "" && searchString === null) {
      getQuestions();
    } else {
      axios
        .get(
          `${API_URL}/api/v1/questions/?search=${searchString.replaceAll(
            " ",
            "+"
          )}`,
          {
            headers,
          }
        )
        .then((res) => {
          setState(res.data);
          setHasLoaded(true);
        })
        .catch((err) => {
          if (!axios.isAxiosError(err)) {
            console.log(err);
          }
        });
    }
  };

  function encodeOptions(options) {
    const keys = Object.keys(options);
    const rawOptions = keys.map((key, index) => {
    const letter = String.fromCharCode(65 + index);
    return `${letter}: ${options[key]}`;
  });

  return rawOptions.join(', ');
  }

  function updateOptions(options) {
    const keys = Object.keys(options);
    const rawOptions = keys.map((key, index) => {
    console.log("key", key, index)
    return `${options[key]}`;
  });
  return rawOptions.join(', ');
  }


  function decodeOptions(rawOptions) {
    const optionList = rawOptions.split(', ');
  
    const formattedOptions = [];
    let currentOption = '';
    for (let i = 0; i < optionList.length; i++) {
      const option = optionList[i].trim();
      if (/^[A-Z]:/.test(option)) {
        if (currentOption !== '') {
          formattedOptions.push(currentOption);
          currentOption = '';
        }
      }
      currentOption += (currentOption !== '' ? ', ' : '') + option;
    }
  
    if (currentOption !== '') {
      formattedOptions.push(currentOption);
    }
  
    return formattedOptions;
  }
  

  const QuesPost = (data) => {
    var cancelHandler = axios.CancelToken.source();
    const QuesData = {
      client: id,
      description: data.questionDescription,
      options: encodeOptions(data.options),
      category: data.selectedCategory,
      correct_option: data.rawCorrectOptions,
      image: data.sponsorImage,
    };
    axios
      .post(
        `${API_URL}/api/v1/questions/?client=${id}`,
        jsonToFormData(QuesData),
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((res) => {
        navigate("/list-questions");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateQues = (instance) => {
    var cancelHandler = axios.CancelToken.source();
    const QuizData = {
      description: instance.description,
      hint: instance.hint,
      options: updateOptions(instance.options),
      correct_option: instance.correct_option,
      category: instance.category,
      image: instance.image,
    };
    axios
      .patch(
        `${API_URL}/api/v1/questions/${instance.id}/?client=${id}`,
        jsonToFormData(QuizData),
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((res) => {
        navigate("/list-questions");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Delete = (instance) => {
    var cancelHandler = axios.CancelToken.source();
    axios
      .delete(
        `${API_URL}/api/v1/questions/${instance}/?client=${id}`,
        {
          headers,
        },
        {
          cancelToken: cancelHandler.token,
        }
      )
      .then((response) => {
        navigate("/list-questions");
      })
      .catch((error) => {
        alert("There was an error!", error);
      });
  };

  const jsonToFormData = (json) => {
    var form_data = new FormData();
    Object.keys(json).forEach((key) => {
      if (json[key] != null) {
        if (key == "image") {
          if (typeof json[key] != "string") {
            form_data.append(key, json[key]);
          }
        } else {
          form_data.append(key, json[key]);
        }
      }
    });
    return form_data;
  };

  const bulkDeleteQuestions = (ids) => {
    axios
      .post(
        `${API_URL}/api/v1/bulk-delete-questions/`,
        {
          questions: ids,
        },
        {
          headers,
        }
      )
      .then((res) => {
        setState({
          ...state,
          results: state.results.filter(
            (question) => !ids.includes(question.id)
          ),
        });
        navigate("/list-questions");
      })
      .catch((error) => {
        alert("There was an error!", error);
      });
  };

  return {
    state,
    getQuestions,
    getQuestionById,
    bulkDeleteQuestions,
    searchQuestions,
    QuesPost,
    UpdateQues,
    Delete,
    hasLoaded,
  };
}
