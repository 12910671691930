import {
  CAlert,
  CButton,
  CContainer,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../Components/Title";
import resetPassword from "../../Hooks/resetPassword";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [errorVisible, setErrorVisible] = useState(false);
  const [disable, setDisable] = useState(false);

  const navigate = useNavigate();
  const site_url = window.location.origin;

  return (
    <CContainer
      className="d-flex flex-column  justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <Title>Reset Password</Title>
    
      <h1>Reset Password</h1>
      <span>Enter your registered email to get a reset password email</span>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (email) {
            setDisable(true);
            resetPassword({ email, site_url }).then((res) => {
              setDisable(false);
              console.log("res", res)
              setError("Email has been sent Successfully ");
              setErrorVisible(true);
            });
          } else {
            setError("Please enter your email");
            setErrorVisible(true);
          }
        }}
      >
        <CRow>
          <CFormLabel htmlFor="email" className="col-form-label">
            Email
          </CFormLabel>
        </CRow>
        <CFormInput
          placeholder="Enter email here"
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          disabled={disable}
        />

        <CAlert
          className="mt-3"
          color="primary"
          // dismissible
          visible={errorVisible}
          onClose={() => {
            setError("");
            setErrorVisible(false);
          }}
        >
          {error}
        </CAlert>

          <div className="d-flex gap-2">
          <CButton color="warning" className="mt-2 mr-2" type="submit">
          Submit
        </CButton>
        <CButton color="secondary " className="mt-2"
          onClick={() => {
            navigate("/");
          }}
        >
          Back

        </CButton>
          </div>
      </form>
    </CContainer>
  );
};

export default ResetPassword;
